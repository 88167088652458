import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { quantile } from 'simple-statistics';
import { useMapState } from '../../context/MapStateContext';


const calculatePercentileValues = (treeData, percentileRange, customTarg) => {
    if (!treeData || !treeData.features || treeData.features.length === 0 || !customTarg.targetFeature) {
        return null;
    }

    const values = treeData.features
        .map(feature => {
            const targetValue = feature.properties[customTarg.targetFeature];
            const normalizationValue = customTarg.normalizationFeature ? feature.properties[customTarg.normalizationFeature] : 1;
            return targetValue / normalizationValue;
        })
        .filter(value => value !== null && value !== undefined && value !== 0)
        .sort((a, b) => a - b);

    if (values.length === 0) {
        return null;
    }
    
    const lowerThreshold = quantile(values, percentileRange[0] / 100);
    const upperThreshold = quantile(values, percentileRange[1] / 100);


    const lowZoneValues = values.filter(v => v < lowerThreshold);
    const middleZoneValues = values.filter(v => v >= lowerThreshold && v <= upperThreshold);
    const highZoneValues = values.filter(v => v > upperThreshold);

    const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

    return {
        lower: lowerThreshold,
        upper: upperThreshold,
        lowZoneAvg: average(lowZoneValues),
        middleZoneAvg: average(middleZoneValues),
        highZoneAvg: average(highZoneValues)
    };
};

const ShortlinkDialog = ({ setShareGeoDialog, setShortlink, blockName, currentScanId, plotType, treeData, stats }) => {
    const { plotConfigs, customTarg, isTaskMapDialogOpen, setIsTaskMapDialogOpen } = useMapState();

    // TODO this can be refactored to pass around config rather than plottype
    const [config, setConfig] = useState(plotConfigs[plotType]);

    const [percentileRange, setPercentileRange] = useState([33, 67]);
    const [percentileValues, setPercentileValues] = useState(null);

    useEffect(() => {
        const values = calculatePercentileValues(treeData, percentileRange, customTarg);
        setPercentileValues(values);
    }, [treeData, percentileRange, config, customTarg]);

    // Destructure values from percentileValues state
    const { lower: lowerValue, upper: upperValue, lowZoneAvg, middleZoneAvg, highZoneAvg } = percentileValues || {};

    const [isDownloading, setIsDownloading] = useState(false); 
    const [dataDetails, setDataDetails] = useState('')
    const [additionalDataDetails, setAdditionalDataDetails] = useState('');
    const [dataHeader, setDataHeader] = useState(config?.caption ? `${config.caption} Zone Heatmap` : 'Zone Heatmap')
    const [lowerColor, setLowerColor] = useState('red'); 
    const [middleColor, setMiddleColor] = useState('limegreen'); 
    const [highColor, setHighColor] = useState('cornflowerblue'); 

    useEffect(() => {
        setConfig(plotConfigs[plotType]);
        setDataHeader(plotConfigs[plotType]?.caption ? `${plotConfigs[plotType].caption} Zone Heatmap` : 'Zone Heatmap')
    }, [plotType]);

    const colorOptions = {
        'red': 'red',
        'orange': 'darkorange',
        'yellow': 'yellow',
        'green': 'limegreen',
        'blue': 'cornflowerblue',
        'purple': 'rebeccapurple',
        'pink': 'DeepPink',
        'brown': 'sienna',
        'white': '#FFFFFF',
        'black': '#000000'
    };

    const [language, setLanguage] = useState('en');

    const calculateRangeInfo = (start, end) => {
        const percentage = end - start;
        const acreage = ((percentage / 100) * stats.block_acreage).toFixed(2);
        const trees = Math.round((percentage / 100) * stats.total_trees_estimated);
        return { percentage, acreage, trees };
    };

    useEffect(() => {
        if (customTarg) {
            if (lowerValue && upperValue) {
                setDataDetails(`Lower threshold: ${lowerValue.toFixed(2)}${customTarg.targetUnit ? ` ${customTarg.targetUnit}` : ''} ${customTarg.normalizationUnit ? `${customTarg.normalizationUnit}` : ''}\nUpper threshold: ${upperValue.toFixed(2)}${customTarg.targetUnit ? ` ${customTarg.targetUnit}` : ''} ${customTarg.normalizationUnit ? `${customTarg.normalizationUnit}` : ''}`)
            }
        }
    }, [lowerValue, upperValue, customTarg]);

    const calculateInputSaved = () => {
        const lowPercentage = percentileRange[0];
        const middlePercentage = percentileRange[1] - percentileRange[0];
        const highPercentage = 100 - percentileRange[1];

        const precisionInputPercentage = lowPercentage * lowZoneAvg/upperValue + middlePercentage * middleZoneAvg/upperValue + highPercentage * highZoneAvg/upperValue
        return (100 -precisionInputPercentage)
        
    };

    const translateText = async (targetLanguage) => {
        try {
            const response = await axios.post('/translate', {
                texts: [dataHeader, additionalDataDetails, dataDetails],
                targetLanguage: targetLanguage
            });
            const [translatedHeader, translatedAdditional, translatedDetails] = response.data.translations;
            setDataHeader(translatedHeader);
            setAdditionalDataDetails(translatedAdditional);
            setDataDetails(translatedDetails);
        } catch (error) {
            console.error('Translation error:', error);
            toast.error('Failed to translate. Using original text.');
        }
    };

    const handlePercentileChange = (event, newValue) => {
        setPercentileRange(newValue);
    };

    const handleLanguageToggle = (event) => {
        const selectedLanguage = event.target.checked ? 'es' : 'en';
        setLanguage(selectedLanguage);
        translateText(selectedLanguage);
    };

    const handleSubmit = async () => {
        setIsDownloading(true);  // Disable buttons and change button text to "Downloading"
        const payload = {
            "block_id": blockName,
            "scan_id": currentScanId,
            'plot_type': plotType,
            "lower_percentile": percentileRange[0],
            "upper_percentile": percentileRange[1],
            "lower_threshold": lowerValue,
            "upper_threshold": upperValue,
            "data_details": dataDetails + '\n' + additionalDataDetails,
            "data_header": dataHeader,
            'lower_color': lowerColor,
            'middle_color': middleColor,
            'high_color': highColor,
            "target_stat": customTarg.targetFeature,
            "normalization_stat": customTarg.normalizationFeature,
        };

        try {
            const response = await axios({
                method: 'post',
                url: '/export/shortlink_geojson',
                data: payload,
            });
            setShortlink(response.data.shortlink)
            toast.success("Generated link successfully");
        } catch (error) {
            console.error(error);
            toast.error("Error: Failed to generate link");
        } finally {
            setIsDownloading(false);  // Re-enable buttons and revert button text after download is done or fails
        }
        setIsTaskMapDialogOpen(false);
        setShareGeoDialog(true)
    };

    const handleClose = () => {
        setIsTaskMapDialogOpen(false);
    };

    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (!config) {
        return null;
    }
    
    return (
        <Dialog 
        maxWidth='sm' 
        fullWidth 
        open={isTaskMapDialogOpen} 
        onClose={() => setIsTaskMapDialogOpen(false)} // Allow closing by clicking outside
    >            
    <DialogTitle>Enter Percentiles for Zones
            <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
          </IconButton>
            </DialogTitle>
            <DialogContent >
                <DialogContentText>
                    Adjust the slider to define the low and high zones.
                </DialogContentText>
                <div style={{ marginTop: 36, marginBottom: 32 }}>
                    <Slider
                        value={percentileRange}
                        onChange={handlePercentileChange}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => `${value}%`}
                        aria-labelledby="range-slider"
                        min={1}
                        max={99}
                        disabled={isDownloading}
                    />
                    {lowerValue && upperValue && (
                        <div className="flex justify-between items-center mt-2">
                            <Typography variant="caption">
                                Lower threshold: {lowerValue.toFixed(2)}{customTarg.targetUnit ? ` ${customTarg.targetUnit}` : ''} {customTarg.normalizationUnit ? `${customTarg.normalizationUnit}` : ''}
                            </Typography>
                            <Typography variant="caption" className="font-bold">
                                {config.caption} {customTarg.normalizationUnit ? `${customTarg.normalizationUnit}` : ''}
                            </Typography>
                            <Typography variant="caption">
                                Upper threshold: {upperValue.toFixed(2)}{customTarg.targetUnit ? ` ${customTarg.targetUnit}` : ''} {customTarg.normalizationUnit ? `${customTarg.normalizationUnit}` : ''}
                            </Typography>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16, gap: '16px' }}>
                    {/* Low Range Color Selector */}
                    <FormControl fullWidth>
                        <InputLabel id="lower-color-label">Low Zone Color</InputLabel>
                        <Select
                            labelId="lower-color-label"
                            value={lowerColor}
                            onChange={(e) => setLowerColor(e.target.value)}
                            disabled={isDownloading}
                        >
                            {Object.entries(colorOptions).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key.charAt(0).toUpperCase() + key.slice(1)}</MenuItem>
                            ))}
                        </Select>
                        <div className="mt-2">
                            {(() => {
                                const { percentage, acreage, trees } = calculateRangeInfo(0, percentileRange[0]);
                                return (
                                    <>
                                        <Typography variant="caption" display="block"> {acreage} acres ({percentage}%)</Typography>
                                        <Typography variant="caption" display="block">
                                            {trees} trees
                                            <Tooltip title="Total trees in trunk scans * range percentage">
                                                <span style={{ marginLeft: '5px' }}>
                                                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                                                    </span>
                                            </Tooltip>
                                        </Typography>
                                    </>
                                );
                            })()}
                        </div>
                    </FormControl>

                    {/* Middle Range Color Selector */}
                    <FormControl fullWidth>
                        <InputLabel id="middle-color-label">Middle Zone Color</InputLabel>
                        <Select
                            labelId="middle-color-label"
                            value={middleColor}
                            onChange={(e) => setMiddleColor(e.target.value)}
                            disabled={isDownloading}
                        >
                            {Object.entries(colorOptions).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key.charAt(0).toUpperCase() + key.slice(1)}</MenuItem>
                            ))}
                        </Select>
                        <div className="mt-2">
                            {(() => {
                                const { percentage, acreage, trees } = calculateRangeInfo(percentileRange[0], percentileRange[1]);
                                return (
                                    <>
                                        <Typography variant="caption" display="block"> {acreage} acres ({percentage}%)</Typography>
                                        <Typography variant="caption" display="block">{trees} trees</Typography>
                                    </>
                                );
                            })()}
                        </div>
                    </FormControl>

                    {/* High Range Color Selector */}
                    <FormControl fullWidth>
                        <InputLabel id="high-color-label">High Zone Color</InputLabel>
                        <Select
                            labelId="high-color-label"
                            value={highColor}
                            onChange={(e) => setHighColor(e.target.value)}
                            disabled={isDownloading}
                        >
                            {Object.entries(colorOptions).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key.charAt(0).toUpperCase() + key.slice(1)}</MenuItem>
                            ))}
                        </Select>
                        <div className="mt-2">
                            {(() => {
                                const { percentage, acreage, trees } = calculateRangeInfo(percentileRange[1], 100);
                                return (
                                    <>
                                        <Typography variant="caption" display="block"> {acreage} acres ({percentage}%)</Typography>
                                        <Typography variant="caption" display="block">{trees} trees</Typography>
                                    </>
                                );
                            })()}
                        </div>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16, gap: '16px' }}>
                    <TextField
                        label="Title"
                        fullWidth
                        value={dataHeader}
                        onChange={(e) => setDataHeader(e.target.value)}
                        disabled={isDownloading}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 16 }}>
                    <TextField
                        fullWidth
                        label="Details"
                        value={additionalDataDetails}
                        onChange={(e) => setAdditionalDataDetails(e.target.value)}
                        disabled={isDownloading}
                        multiline
                        rows={4}  // Increase the number of rows
                    />
                </div>
                <div className="flex items-center justify-center mt-4">
                    <Typography variant="body1">Data Language:</Typography>
                    <div className="flex items-center ml-4">
                        <Typography variant="body1">English</Typography>
                        <Switch
                            checked={language === 'es'}
                            onChange={handleLanguageToggle}
                            disabled={isDownloading}
                            className="mx-2"
                        />
                        <Typography variant="body1">Español</Typography>
                    </div>
                </div>
                {/* { plotType === 'uniform' && <div className="mt-4 bg-green-100 p-4 rounded-md">
                    <Typography variant="h6" className="text-green-800 text-center flex flex-col items-center justify-center">
                        You could save up to {(calculateInputSaved()).toFixed(2)}% of input costs if adjusted for zones*
                        <Typography variant="caption" display="block" className="text-green-700 mt-1">
                            *vs if entire block assumed every tree was at the upper threshold ({upperValue !== undefined ? upperValue.toFixed(2) : 'N/A'}{config.unit ? ` ${config.unit} per tree` : ''})
                        </Typography>
                    </Typography>

                    <Accordion 
                        expanded={expanded === 'panel1'} 
                        onChange={handleAccordionChange('panel1')}
                        className="mt-2"
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            '&:before': {
                                display: 'none',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className="text-green-800">How we calculate this number</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" className="text-green-800 mt-2">
                                Using a weighted average of precision inputs for each zone:
                            </Typography>
                            <div className="bg-white p-2 rounded mt-1">
                                <img 
                                    src={`https://latex.codecogs.com/svg.latex?\\large%201%20-%20(L_{\\%}%20\\cdot%20\\frac{L_{avg}}{U}%20+%20M_{\\%}%20\\cdot%20\\frac{M_{avg}}{U}%20+%20H_{\\%}%20\\cdot%20\\frac{H_{avg}}{U})`} 
                                    alt="LaTeX formula" 
                                    className="mx-auto"
                                />
                            </div>
                            <Typography variant="caption" className="block mt-2 text-green-800">
                                Where:
                                <br />
                                L<sub>%</sub> = Low zone percentage = {percentileRange[0]}%
                                <br />
                                M<sub>%</sub> = Middle zone percentage = {percentileRange[1] - percentileRange[0]}%
                                <br />
                                H<sub>%</sub> = High zone percentage = {100 - percentileRange[1]}%
                                <br />
                                L<sub>avg</sub> = Low zone average
                                <br />
                                M<sub>avg</sub> = Middle zone average
                                <br />
                                H<sub>avg</sub> = High zone average
                                <br />
                                U = Upper threshold value
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                } */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} variant='contained' fullWidth disabled={isDownloading}>
                    {isDownloading ? 'Generating...' : 'Generate Link'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShortlinkDialog;
