import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const UnitToggle = ({ isImperial, setIsImperial }) => {

    const handleUnitToggle = (event) => {
        setIsImperial(event.target.checked);
    };
    
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={isImperial}
                    onChange={handleUnitToggle}
                />
            }
            label={"Toggle Imperial Units"}
        />
    );
};

export default UnitToggle;