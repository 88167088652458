import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { EntityType, StageType } from '../common/types';
import * as GraphNames from '../constants/graphNames';
import { Card } from './Cards';
import { getTerminology } from '../i18n';

export const Charts = ({
    entityType,
    currentScanId,
    handleChange,
    blockId,
    chartType,
    viewMode,
    stageType,
    plotType,
    setChartType,
    scanInfo
}) => {
    const [openGraphDialog, setOpenGraphDialog] = useState(false);
    const [imgError, setImgError] = useState(false);
    const title = 'Charts';
    const chartOptions = getChartOptions(entityType, stageType);
    const imgEndpoint = '/graph/img';
    const imageParams = new URLSearchParams({
        level: viewMode,
        block: blockId,
        graph_type: chartType,
        scan_id: currentScanId
    });

    const imgSrc = `${imgEndpoint}?${imageParams.toString()}`;
    const handleClickOpen = () => setOpenGraphDialog(true);
    const handleClose = () => setOpenGraphDialog(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setImgError(false);
    }, [imgSrc]);

    const handleImageLoad = () => {
        setIsLoading(false);
        setImgError(false);
    };

    const handleImageError = () => {
        setIsLoading(false);
        setImgError(true);
    };

    return (
        <Card title={title} showButton defaultOpen={true} description={'Select a chart type to view data progression over time.'}>
            <div>
                <Box sx={{ minWidth: 120 }} className='py-4'>
                    <FormControl fullWidth>
                        <InputLabel id="chart-select-label">Chart Type</InputLabel>
                        <Select
                            labelId="chart-select-label"
                            id="chart-select"
                            value={chartType}
                            label="Age"
                            onChange={handleChange}
                        >
                            {chartOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                    {getTerminology(option.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    {isLoading && (
                        <div className="w-full h-full flex items-center justify-center">
                            <CircularProgress />
                        </div>
                    )}
                    {imgSrc && !imgError ? (
                        <img
                            src={imgSrc}
                            onError={handleImageError}
                            onLoad={handleImageLoad}
                            onClick={handleClickOpen}
                            className={`w-full h-full object-cover cursor-zoom-in ${isLoading ? 'hidden' : ''}`}
                        />
                    ) : (
                        !isLoading && (
                            <div className="w-full h-full flex items-center justify-center bg-gray-100 text-gray-500">
                                Not enough data points for chart
                            </div>
                        )
                    )}
                </div>
                <GraphDownloadDialog
                    open={openGraphDialog}
                    handleClose={handleClose}
                    imgSrc={imgSrc}
                    blockId={blockId}
                    chartType={chartType}
                    currentScanId={currentScanId}
                    scanInfo={scanInfo}
                />
            </div>
        </Card>
    );
};


const getChartOptions = (entityType, stageType) => {
    // Base charts that should always be available
    const trunkCharts = [
        { value: 'trunk_diam_hist_', label: `Trunk diameter histogram` },
        { value: 'trunk_size_hist_', label: `Trunk cross-sectional area histogram` },
    ];

    if (entityType === EntityType.Fruits) {
        let additionalCharts = [];
        
        if (stageType === StageType.Fruit) {
            additionalCharts = [
                { value: 'fruit_count_', label: `Fruit Count Distribution` },
                { value: 'fruit_histogram_', label: `Fruit Size Histogram` },
                { value: 'packout_histogram_', label: 'Fruit Packout Histogram' },
                { value: 'row_bars_', label: `Fruits Per Row` },
                { value: 'seen_count_', label: `Scans Per Row` },
                { value: 'fruit_color_histogram_', label: `Fruit Color Histogram` },
                { value: 'fruit_width_over_time_', label: `Avg Fruit Width over Time` },
                { value: 'fruit_volume_over_time_', label: `Avg Fruit Volume over Time` },
                { value: 'fruit_per_tree_over_time_', label: `Avg Fruits per Tree over Time` },
                { value: GraphNames.FRUIT_VOLUME_TIME_HISTOGRAM_PREFIX, label: `Fruit Width Distribution over Time` },
            ];
        } else if (stageType === StageType.EarlyFruitSet) {
            additionalCharts = [
                { value: 'fruitlet_histogram_', label: `Fruitlet Width Histogram` },
                { value: 'seen_count_', label: `Scans Per Row` },
                { value: 'row_sizes_', label: `Avg Fruit Volume per row` },
                { value: 'fruit_width_over_time_', label: `Avg Fruit Width over Time` },
                { value: GraphNames.FRUIT_VOLUME_TIME_HISTOGRAM_PREFIX, label: `Fruit Width Distribution over Time` },
            ];
        } else {
            additionalCharts = [
                { value: 'row_bars_', label: `Fruits Per Row` },
                { value: 'seen_count_', label: `Scans Per Row` },
                { value: 'fruit_count_', label: `Fruit Count Distribution` },
            ];
        }
        return [...additionalCharts, ...trunkCharts];
    } else if (entityType === EntityType.Trees) {
        return trunkCharts;
    }
    
    return trunkCharts;
};

export const GraphDownloadDialog = ({ open, handleClose, imgSrc, blockId, chartType, currentScanId, scanInfo }) => {

    const handleDownloadImage = () => {
        console.log("running block report ")
        const data = {
            scan_id: currentScanId,
            block_id: blockId,
            chart_type: chartType
        };

        fetch('/export/download_chart', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.blob())
            .then(blob => {
                // Create a Blob from the PDF Stream
                const fileURL = window.URL.createObjectURL(blob);
                console.log("FILE ", fileURL)

                // Get the current date and format it
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `${chartType}${scanInfo.raw_scan_name}_${blockId}.png`;
                link.click();
            });
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <div style={{ height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'relative', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={imgSrc}
                        style={{
                            height: '75vh',
                            width: '75vw',
                            objectFit: 'contain',
                            objectPosition: 'center',
                        }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <DialogActions>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            onClick={handleDownloadImage}
                            variant='contained'
                            color="primary"
                            style={{ width: '400px', marginRight: '10px' }}
                        >
                            Download Chart
                        </Button>
                        <Button
                            onClick={handleClose}
                            variant='contained'
                            color="primary"
                            style={{ width: '400px' }}
                        >
                            Exit
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
};
