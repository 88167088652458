import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Card } from './Cards';

export const TotalWeightCard = ({ stats }) => {
  // Inputs
  const [historicalClusterWeight, setHistoricalClusterWeight] = useState(0.67);
  const [clustersPerBud, setClustersPerBud] = useState(1.5);
  const [nonFruitBudPercent, setNonFruitBudPercent] = useState(5);
  const [percentRowsScanned, setPercentRowsScanned] = useState();
  
  // Results
  const [showModal, setShowModal] = useState(false);
  const [totalPounds, setTotalPounds] = useState(0);
  const [totalTons, setTotalTons] = useState(0);
  const [loading, setLoading] = useState(false);

  // Check if any inputs are blank or zero
  const isSubmitDisabled = 
    historicalClusterWeight === '' || 
    clustersPerBud === '' || 
    nonFruitBudPercent === '' || 
    percentRowsScanned === '' ||
    percentRowsScanned === 0

  const handleHistoricalClusterWeightChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setHistoricalClusterWeight(value);
    }
  };

  const handleClustersPerBudChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setClustersPerBud(value);
    }
  };

  const handleNonFruitBudPercentChange = (event) => {
    const value = event.target.value;
    if (value >= 0 && value <= 100) {
      setNonFruitBudPercent(value);
    }
  };

  const handlePercentRowsScannedChange = (event) => {
    const value = event.target.value;
    if (value >= 0 && value <= 100) {
      setPercentRowsScanned(value);
    }
  };

  const handleSubmit = async () => {
    setTotalPounds(0);
    setTotalTons(0);
    setLoading(true);
    setShowModal(true);

    const fruitBudPercent = (100 - nonFruitBudPercent) / 100;
    const rowScaleFactor = 100 / percentRowsScanned;

    const total_fruit_calibrated = stats.total_fruit_calibrated;
    
    const pounds = historicalClusterWeight * clustersPerBud * total_fruit_calibrated * fruitBudPercent * rowScaleFactor;
    const tons = pounds / 2000;
    
    // Simulate a delay to show loading state
    await new Promise((resolve) => setTimeout(resolve, 1000));
    
    setTotalPounds(pounds);
    setTotalTons(tons);
    setLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };



  return (
    <Card
      title={`Preliminary Yield Estimate from Bud Count`}
      showButton
      defaultOpen={true}
      description="Estimate tons and tons per acre from historical cluster weight data, and number of estimated clusters per bud."
    >
      {stats && stats.total_fruit_calibrated > 0 ? (
        <div className="flex flex-col items-center w-full">
          <div className="w-full max-w-md">
            <div className="mb-4 flex flex-col items-center">
              <p className="text-center font-medium mb-1">Historical Average Cluster Weight (lbs)</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '110px' }}
                value={historicalClusterWeight}
                onChange={handleHistoricalClusterWeightChange}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0
                }}
                endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
              />
            </div>

            <div className="mb-4 flex flex-col items-center">
              <p className="text-center font-medium mb-1">Number of eventual Clusters per Bud</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '110px' }}
                value={clustersPerBud}
                onChange={handleClustersPerBudChange}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0
                }}
              />
            </div>

            <div className="mb-4 flex flex-col items-center">
              <p className="text-center font-medium mb-1">% of Buds that don&apos;t become Fruit</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '110px' }}
                value={nonFruitBudPercent}
                onChange={handleNonFruitBudPercentChange}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0,
                  max: 100
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>

            <div className="mb-4 flex flex-col items-center">
              <p className="text-center font-medium mb-1">Percent of total rows scanned</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '110px' }}
                value={percentRowsScanned}
                onChange={handlePercentRowsScannedChange}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0,
                  max: 100
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>

            <div className="flex flex-col items-center mt-4">
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{ textTransform: 'uppercase' }}
                disabled={isSubmitDisabled}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-yellow-50 border border-yellow-100 text-yellow-700 px-4 py-3 rounded text-center" role="alert">
          <p className="font-medium">
            Ground truth counts required for this calculation
          </p>
        </div>
      )}

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Weight Estimate Results</DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="p-4">
    
            <div className="mb-4 border-b pb-3">
                <Typography variant="subtitle1" className="font-medium">
                Total Calibrated Buds:
                </Typography>
                <Typography variant="h5" className="text-center font-bold text-blue-600 mt-2">
                {Math.round(stats?.total_fruit_calibrated || 0).toLocaleString()} buds
                </Typography>
            </div>
              
              <div className="mb-4 border-b pb-3">
                <Typography variant="subtitle1" className="font-medium">
                  Total Estimated Weight:
                </Typography>
                <Typography variant="h5" className="text-center font-bold text-green-700 mt-2">
                  {totalPounds.toLocaleString(undefined, { maximumFractionDigits: 2 })} lbs
                </Typography>
              </div>
              
              <div className="mb-4 border-b pb-3">
                <Typography variant="subtitle1" className="font-medium">
                  Total Estimated Tons:
                </Typography>
                <Typography variant="h4" className="text-center font-bold text-green-800 mt-2">
                  {totalTons.toLocaleString(undefined, { maximumFractionDigits: 2 })} tons
                </Typography>
              </div>
              
              {stats && stats.block_acreage && (
                <div className="mt-4">
                  <Typography variant="subtitle1" className="font-medium">
                    Estimated Tons per Acre:
                  </Typography>
                  <Typography variant="h5" className="text-center font-bold text-blue-700 mt-2">
                    {(totalTons / stats.block_acreage).toLocaleString(undefined, { maximumFractionDigits: 2 })} tons/acre
                  </Typography>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
}; 