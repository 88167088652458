import i18n, { t } from 'i18next';
import { initReactI18next } from 'react-i18next';
import defaultTerminology from './locales/default.json';
import grapeTerminology from './locales/grape.json';

// Use this function to get the correct terminology for a given string based on the current fruit type
// See default.json and grapes.json for examples - each json must have the same set keys
export const getTerminology = (string: string) => {
    if (!string) return '';
    return string.split(' ').map((word: string) => {
        const translated = t(word);
        return translated !== word ? translated : word;
    }).join(' ');
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      default: {
        translation: defaultTerminology
      },
      grape: {
        translation: grapeTerminology
      }
    },
    lng: 'default',
    fallbackLng: 'default',

  });


export default i18n; 