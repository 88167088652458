import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import loading from '../assets/loading.gif';
import { ViewMode } from '../common/types';

export const Card = React.forwardRef(({ children, title, isLoading, showButton = false, defaultOpen = true, description }, ref) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div ref={ref} className='bg-white mx-3 px-5 py-3 rounded-xl drop-shadow-md'>
      <div className='flex justify-between items-center'>
        <h1 className='text-xl'>
          <span className='font-bold'>{title}</span>
          <p className='pb-1 text-xs text-slate-500 text-left'>{description}</p>
          {isLoading ? (
            <img src={loading} alt='loading...' width={35} height={35} />
          ) : null}
        </h1>
        {showButton && (
          <button
            onClick={handleToggle}
            className='text-md drop-shadow-sm text-gray-600 absolute top-2 right-2 p-1 focus:outline-none'
          >
            {isOpen ? <FontAwesomeIcon icon={faChevronUp}/> :  <FontAwesomeIcon icon={faChevronDown}/>}

          </button>
        )}
      </div>
      {isOpen && children}
    </div>
  );
});
Card.displayName = 'Card';

export const OrchardCard = ({ viewMode, isExpanded, children, title, isLoading, showButton = false, defaultOpen = true, description }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
<div className={`bg-white mx-3 px-5 py-3 rounded-xl drop-shadow-md relative ${isExpanded || viewMode !== ViewMode.Block? 'flex flex-col flex-grow overflow-hidden min-h-full' : 'flex flex-col'}`}>
      <div className='flex justify-between items-center'>
        <h1 className='text-xl'>
          <span className='font-bold'>{title}</span>
          <p className='pb-1 text-xs text-slate-500 text-left'>{description}</p>
          {isLoading ? (
            <img src={loading} alt='loading...' width={35} height={35} />
          ) : null}
        </h1>
        {showButton && (
          <button
            onClick={handleToggle}
            className='text-md drop-shadow-sm text-gray-600 absolute top-2 right-2 p-1 focus:outline-none'
          >
            {isOpen ? 'Close' : 'Open'}
          </button>
        )}
      </div>
      {isOpen && children}
    </div>
  );
};
OrchardCard.displayName = 'OrchardCard';

export const HorizontalCard = ({ children, onClick, isDisabled }) => (
  <div 
      onClick={!isDisabled ? onClick : null}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      className={`cursor-pointer ${!isDisabled ? 'hover:bg-gray-200' : 'bg-gray-200'} w-full bg-white px-5 py-3 rounded-xl flex flex-row items-center justify-center drop-shadow-md border border-gray-900`} 
  >
      {children}
  </div>
);
HorizontalCard.displayName = 'HorizontalCard';