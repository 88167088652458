import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  TextField
} from '@mui/material';
import { Feature } from 'geojson';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { TreeSearchIcon } from '../assets/Icons';
import { ViewMode } from '../common/types';
import { getTerminology } from '../i18n';
import { EnableDrawControl } from './MapControls';
interface MapToolsProps {
  lat: number;
  lng: number;
  zoom: string;
  viewMode: ViewMode;
  drawEnabled: boolean;
  setDrawEnabled: (enabled: boolean) => void;
  setIsGroundTruthDialogOpen: (open: boolean) => void;
  treeSearchId: string;
  setTreeSearchId: (id: string) => void;
  map: mapboxgl.Map | null;
  treeData: any;
}

export const MapTools: React.FC<MapToolsProps> = ({
  lat,
  lng,
  zoom,
  viewMode,
  drawEnabled,
  setDrawEnabled,
  setIsGroundTruthDialogOpen,
  treeSearchId,
  setTreeSearchId,
  map,
  treeData,
}) => {
  
const [showTreeSearch, setShowTreeSearch] = useState(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowTreeSearch(false);
        setTreeSearchId('');
      }
    };

    if (showTreeSearch) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTreeSearch]);

  const handleCopyCoordinates = () => {
    const coordinates = `${lat.toFixed(4)}, ${lng.toFixed(4)}`;
    const textarea = document.createElement('textarea');
    textarea.value = coordinates;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      toast('Coordinates copied to clipboard', { icon: '📋' });
    } catch (error) {
      console.error('Failed to copy coordinates:', error);
      toast.error('Failed to copy coordinates');
    } finally {
      document.body.removeChild(textarea);
    }
  };

    const handleTreeSearch = () => {
        setShowTreeSearch(!showTreeSearch);
        if (showTreeSearch) {
            setTreeSearchId('');
        }
    };

  const handleTreeSearchSubmit = () => {
    const treeId = parseInt(treeSearchId);
    if (isNaN(treeId)) {
      toast.error('Tree ID not found');
      return;
    }

    if (!map) return;

    // Remove any existing highlight layer
    if (map.getLayer('highlighted-tree')) {
      map.removeLayer('highlighted-tree');
    }
    if (map.getSource('highlighted-tree')) {
      map.removeSource('highlighted-tree');
    }

    const targetTree = treeData?.features?.find(
      (tree: Feature) => tree.properties?.master_tree_id === Number(treeSearchId)
    );

    if (targetTree && targetTree.geometry && 'coordinates' in targetTree.geometry) {
      const [longitude, latitude] = targetTree.geometry.coordinates;

      // Fly to the tree location
      map.flyTo({
        center: [longitude, latitude],
        zoom: 20,
        essential: true
      });

      // Add a new source and layer for the highlighted tree
      map.addSource('highlighted-tree', {
        type: 'geojson',
        data: targetTree
      });

      map.addLayer({
        id: 'highlighted-tree',
        type: 'circle',
        source: 'highlighted-tree',
        paint: {
          'circle-radius': 12,
          'circle-color': 'transparent',
          'circle-stroke-width': 3,
          'circle-stroke-color': 'white'
        }
      });

      // Remove the highlight after 3 seconds
      setTimeout(() => {
        if (map) {
          if (map.getLayer('highlighted-tree')) {
            map.removeLayer('highlighted-tree');
          }
          if (map.getSource('highlighted-tree')) {
            map.removeSource('highlighted-tree');
          }
        }
      }, 3000);
    } else {
      toast.error('Tree ID not found');
    }
  };

  return (
    <div className="absolute inset-0 z-25 flex flex-col justify-start space-y-2 p-2 pointer-events-none">
      <div className="pointer-events-auto inline-flex">
        <button
          className="bg-white border border-gray-700 text-black px-2 py-2 text-xs font-mono rounded-lg cursor-context-menu"
          onClick={handleCopyCoordinates}
        >
          {lat.toFixed(5)}, {lng.toFixed(5)} | Zoom: {zoom}
        </button>
      </div>

      {viewMode === ViewMode.Block && (
        <>
          <div className="pointer-events-auto inline-flex w-fit">
            <EnableDrawControl
              drawEnabled={drawEnabled}
              setDrawEnabled={setDrawEnabled}
            />
          </div>
          <div className="pointer-events-auto inline-flex w-fit">
              <div 
                ref={searchContainerRef}
                className='flex items-center font-mono bg-white text-xs text-black border border-gray-900 rounded-lg'
              >
                {showTreeSearch ? (
                  <div className="flex items-center py-1">
                    <TextField
                      size="small"
                      value={treeSearchId}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setTreeSearchId(value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleTreeSearchSubmit();
                        }
                      }}
                      placeholder="Enter Tree ID"
                      InputProps={{
                        className: 'h-6',
                        sx: { 
                          width: '185px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          }
                        },
                        endAdornment: (
                          <div className="flex">
                            <IconButton
                              size="small"
                              onClick={handleTreeSearchSubmit}
                            >
                              <SearchIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setShowTreeSearch(false);
                                setTreeSearchId('');
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </div>
                        ),
                      }}
                    />
                  </div>
                ) : (
                  <div 
                    className='flex items-center px-2 py-2 cursor-pointer'
                    onClick={handleTreeSearch}
                  >
                    <div className='flex-shrink-0'>
                      <TreeSearchIcon />
                    </div>
                    <div className='ml-1'>
                      {getTerminology('Search Tree ID')}
                    </div>
                  </div>
                )}
              </div>
          </div>
        </>
      )}
    </div>
  );
};