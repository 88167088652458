export const FRUIT_HIST_PREFIX = "fruit_histogram_"
export const FRUITLET_HIST_PREFIX = "fruitlet_histogram_"
export const FRUIT_COUNT_PER_ROW_PREFIX = "row_bars_"
export const FRUIT_COUNT_PER_TREE_HIST_PREFIX = "fruit_count_"
export const FRUIT_WIDTH_OVER_TIME_PREFIX = "fruit_width_over_time_"
export const FRUIT_PER_TREE_OVER_TIME_PREFIX = "fruit_per_tree_over_time_"
export const FRUIT_SIZE_PER_ROW_PREFIX = "row_sizes_"
export const WEIGHT_OZ_HIST_PREFIX = "weight_oz_histogram_"
export const WEIGHT_G_HIST_PREFIX = "weight_g_histogram_"
export const FRUIT_COLOR_HIST_PREFIX = 'fruit_color_histogram_'
export const TRUNK_DIAM_HIST_PREFIX = 'trunk_diam_hist_'
export const TRUNK_SIZE_HIST_PREFIX = 'trunk_size_hist_'
export const FRUIT_VOLUME_TIME_HISTOGRAM_PREFIX = 'fruit_volume_time_histogram_'