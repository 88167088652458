import { library } from '@fortawesome/fontawesome-svg-core';
import { faAppleWhole, faArrowDown, faArrowRight, faBorderAll, faCalendar, faCalendarDays, faCamera, faCaretLeft, faCaretRight, faChartArea, faChartSimple, faDownload, faEnvelope, faHardDrive, faHashtag, faHome, faLeaf, faLink, faLinkSlash, faLocationCrosshairs, faLocationDot, faMessage, faPaperPlane, faPrint, faQuestion, faRuler, faSeedling, faSpa, faStopwatch, faTag, faTowerCell, faTree, faVectorSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { BulkExport } from './BulkExport';
import { Block, BlockScanMap, Coordinate, EntityType, FruitType, Orchard, ScanInfo, StageType, Statistics, ViewMode } from './common/types';
import { MapStateProvider } from './context/MapStateContext';
import firebase from './firebase';
import './i18n';
import { Admin } from './routes/AdminMode';
import { Home } from './routes/Home';
import Login from './routes/Login';
import { Setup } from './routes/Setup';


// is there a better way to library.add?
library.add(faSeedling, faStopwatch, faRuler, faCaretRight, faLocationDot, faCaretLeft, faMessage, faEnvelope, faPaperPlane, faXmark, faQuestion, faVectorSquare, faCamera, faLocationCrosshairs, faHardDrive, faTowerCell, faTree, faLink, faLinkSlash, faHome, faAppleWhole, faTag, faPrint, faDownload, faSpa, faChartArea, faLeaf, faHashtag, faCalendarDays, faBorderAll, faArrowRight, faArrowDown, faCalendar, faChartSimple);

const App = () => {
    /* eslint-disable */

    //TODO, apply where possible
    const [orchard, setOrchard] = useState<Orchard | null>(null);
    const [stats, setStats] = useState<Statistics | null>(null);
    const [currentScanId, setCurrentScanId] = useState<number | null>(null)
    const [user, setUser] = useState<firebase.User | null>(null);
    const [initialLocation, setInitialLocation] = useState<Coordinate | null>(null);
    const [orchardCode, setOrchardCode] = useState<string>('');
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Orchard);
    const [treeData, setTreeData] = useState(null);
    const [emitterData, setEmitterData] = useState(null);
    const [blockData, setBlockData] = useState(null);
    const [blockName, setBlockName] = useState<string | null>(null);
    const [blockInfo, setBlockInfo] = useState<Block | null>(null);
    let time = new Date()
    const [timeFilter, setTimeFilter] = useState(() => {
        const storedTime = localStorage.getItem('timestamp');
        const defaultTime = time.toISOString();
        if (storedTime !== null) {
            const storedDateTime = new Date(storedTime);
            return storedDateTime.toISOString() !== '1970-01-01T00:00:00.000Z' ? storedDateTime.toISOString() : defaultTime;
        }
        return defaultTime;
    });
    const [authUser, setAuthUser] = useState<boolean>(false)
    const [adminUser, setAdminUser] = useState<boolean>(false)
    const [controlEnabled, setControlEnabled] = useState<boolean>(true);
    const [progress, setProgress] = useState(0);

    const [entity, setEntity] = useState<EntityType>(EntityType.Fruits);
    const [fruitType, setFruitType] = useState<FruitType>(FruitType.Apple);
    const [blockScanMap, setBlockScanMap] = useState<BlockScanMap>({});
    const [stageType, setStageType] = useState<StageType | null>(null);
    const [scanLoading, setScanLoading] = useState<boolean>(false)
    const [mapCenter, setMapCenter] = useState(null)
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const mapInstance = useRef<mapboxgl.Map | null>(null);
    const [chartType, setChartType] = useState("row_sizes_")
    const [polygonStatDialog, setPolygonStatDialog] = useState<boolean>(false)
    const [addToSidebar, setAddToSidebar] = useState<boolean>(false)
    const [polygonStats, setPolygonStats] = useState<Statistics | null>(null);
    const [plotType, setPlotType] = useState<string | null>(null);
    const [downloadFormat, setDownloadFormat] = useState('share_geojson')
    const [initialLoad, setInitialLoad] = useState<number>(0);
    const [treeViewLong, setTreeViewLong] = useState<number>(0);
    const [treeViewLat, setTreeViewLat] = useState<number>(0);
    const [sectionGeojson, setSectionGeojson] = useState(null);
    const [homePageLoaded, setHomePageLoaded] = useState<boolean>(false);
    const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth * .33);
    const [scanInfo, setScanInfo] = useState<ScanInfo | null>(null);

    const [qualifyingBlocks, setQualifyingBlocks] = useState<string[] | null>(null);


    const dbName = useRef('')
    let didInit = false;

    const [error, setError] = useState<string | null>(null);

    const fetchQualifyingBlocks = async () => {
        try {
            const response = await axios.get('/util/get_qualifying_blocks');
            setQualifyingBlocks(response.data);
        } catch (error) {
            console.error('Error fetching qualifying blocks:', error);
        }
    };
    

    useEffect(() => {
        if (!didInit) {
            // Only run this once
            didInit = true;
            firebase.auth().onAuthStateChanged(async (firebase_user) => {
                try {
                    if (authUser !== true) {
                        setUser(firebase_user);
                        const authenticateUser = await axios.post('/auth_user', {
                            username: firebase_user,
                            timestamp: timeFilter,
                            loggedIn: authUser,
                        });
                        if (authenticateUser.data.error) {
                            throw new Error('Authentication failed');
                        }
                        dbName.current = authenticateUser.data.db;
                        setAuthUser(true);
                        setAdminUser(authenticateUser.data.is_admin ? true : false);
                    }
                    setInitialLoad(20);

                    const [
                        orchDataResponse,
                        blockPointsResponse,
                        blocksToScansResponse,
                    ] = await Promise.all([
                        axios.get('/util/get_orchard').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/geojson/points_block').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/util/get_block_scan_map').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 10);
                            return response;
                        }),
                    ]);
                    const orchData = orchDataResponse.data;
                    setBlockData(blockPointsResponse.data);
                    setBlockScanMap(blocksToScansResponse.data);

                    setInitialLocation(orchData.initial_location);
                    setTreeViewLat(orchData.initial_location.lat);
                    setTreeViewLong(orchData.initial_location.long);
                    setFruitType(orchData.most_recent_scan_fruit_type);
                    setOrchard({
                        name: orchData.orchard_name,
                        address: orchData.address,
                        code: orchData.orchard_id,
                        initialLocation: {
                            lat: orchData.location.lat,
                            long: orchData.location.long,
                        },
                        total_acres: orchData.total_acres,
                        section_code_prefix: orchData.section_code_prefix,
                    });
                    setOrchardCode(orchData.orchard_id);

                    setInitialLoad(100);
                    setProgress(100);
                    setHomePageLoaded(true);
                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
                    setError(errorMessage);
                    setProgress(0);
                    await axios.post('/user/send_error', { message: `${errorMessage}` });
                }
            });
        }
    }, []);

    const { i18n, t } = useTranslation();
    useEffect(() => {
        const languageCode = fruitType === FruitType.Grape ? 'grape' : 'default';
        i18n.changeLanguage(languageCode);
    }, [fruitType]);

    useEffect(() => {
        if (homePageLoaded) {
            fetchQualifyingBlocks();
        }
    }, [homePageLoaded]);

    useEffect(() => {
        (async () => {
            setIsLoad(true)
            if (blockName && homePageLoaded) {
                setViewMode(ViewMode.Block)
                
                setAddToSidebar(false)
                setCurrentScanId(blockScanMap[blockName].last_scan_id);

                const blockInfo = await axios.get('/util/get_block_info', { params: { 'block_name': blockName } });
                setBlockInfo(blockInfo.data);
                setTreeViewLat(blockInfo.data.location.lat)
                setTreeViewLong(blockInfo.data.location.long)
            }
                setIsLoad(false)

        })();
    }, [blockName, homePageLoaded]);

    useEffect(() => {
        (async () => {
            if (currentScanId) {
                setProgress(0);
                setScanLoading(true);
                try {
                    const apiCalls = [
                        axios.get('/geojson/points', { params: { 'orchard_code': orchardCode, 'scan_id': currentScanId } }),
                        axios.get('/geojson/emitter_points', { params: { 'orchard_code': orchardCode, 'scan_id': currentScanId } }),
                        axios.get('/util/get_scan_info', { params: { 'scan_id': currentScanId } }),
                        axios.post('/util/get_stats', { orchard_code: orchardCode, scan_id: currentScanId }),
                        axios.get('/geojson/section_geojson', { params: { 'orchard_code': orchardCode, 'scan_id': currentScanId } })
                    ];

                    const [
                        treedata,
                        emitterdata,
                        scanInfo,
                        stats,
                        sectionGeojsonResponse
                    ] = await Promise.all(apiCalls);

                    console.log("Stats: ", stats);
                    console.log("Scan Info: ", scanInfo);
                    console.log("Section Geojson", sectionGeojsonResponse.data);
                    console.log("Tree Data: ", treedata.data);
                    console.log("Emitter Data: ", emitterdata.data);

                    setEntity(scanInfo.data.entity_type);
                    setFruitType(scanInfo.data.fruit_type);
                    setScanInfo(scanInfo.data);
                    setStageType(scanInfo.data.stage_type);
                    setTreeData(treedata.data);
                    setEmitterData(emitterdata.data);
                    setSectionGeojson(sectionGeojsonResponse.data);

                    if (scanInfo.data.entity_type === EntityType.Fruits) {
                        if (scanInfo.data.stage_type === StageType.Fruit) {
                            setPlotType('uniform');
                        } else if (scanInfo.data.stage_type === StageType.EarlyFruitSet) {
                            setPlotType('size');
                        } else {
                            setPlotType('uniform');
                        }
                    } else {
                        setPlotType('tree_diam');
                    }

                    setStats(stats.data);
                    setScanLoading(false);
                    setProgress(100);
                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
                    setError(errorMessage);
                    setProgress(0);
                    await axios.post('/user/send_error', { message: `${errorMessage}` });
                }
            }
        })();
    }, [currentScanId]);
    
    if (user) {
        return (
            <div data-testid="router-container">
                <Router>
                    <MapStateProvider initialStageType={stageType}>
                        <Routes>
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route
                            path="/bulk"
                            element={<BulkExport scanInfo={scanInfo} />}
                        />
                        <Route
                            path="/block/:blockName?"
                            element={
                                <Home
                                    orchard={orchard}
                                    orchardCode={orchardCode}
                                    blockData={blockData}
                                    initialLoad={initialLoad}
                                    dbName={dbName}
                                    plotType={plotType}
                                    downloadFormat={downloadFormat}
                                    setDownloadFormat={setDownloadFormat}
                                    setPlotType={setPlotType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    mapInstance={mapInstance}
                                    scanLoading={scanLoading}
                                    setScanLoading={setScanLoading}
                                    blockScanMap={blockScanMap}
                                    entity={entity}
                                    setProgress={setProgress}
                                    progress={progress}
                                    stats={stats}
                                    setCurrentScanId={setCurrentScanId}
                                    blockName={blockName}
                                    setBlockName={setBlockName}
                                    viewMode={viewMode}
                                    setViewMode={setViewMode}
                                    currentScanId={currentScanId}
                                    addToSidebar={addToSidebar}
                                    polygonStats={polygonStats}
                                    stageType={stageType}
                                    blockInfo={blockInfo}
                                    setBlockInfo={setBlockInfo}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSectionGeojson={setSectionGeojson}
                                    setPolygonStats={setPolygonStats}
                                    polygonStatDialog={polygonStatDialog}
                                    setPolygonStatDialog={setPolygonStatDialog}
                                    setAddToSidebar={setAddToSidebar}
                                    isLoad={isLoad}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                    controlEnabled={controlEnabled}
                                    initialLocation={initialLocation}
                                    treeData={treeData}
                                    emitterData={emitterData}
                                    setControlEnabled={setControlEnabled}
                                    setBlockData={setBlockData}
                                    adminUser={adminUser}
                                    treeViewLat={treeViewLat}
                                    treeViewLong={treeViewLong}
                                    setScanInfo={setScanInfo}
                                    sectionGeojson={sectionGeojson}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                    scanInfo={scanInfo}
                                    fruitType={fruitType}
                                    qualifyingBlocks={qualifyingBlocks}
                                    setInitialLocation={setInitialLocation}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <Home
                                    orchard={orchard}
                                    orchardCode={orchardCode}
                                    blockData={blockData}
                                    initialLoad={initialLoad}
                                    dbName={dbName}
                                    plotType={plotType}
                                    downloadFormat={downloadFormat}
                                    setDownloadFormat={setDownloadFormat}
                                    setPlotType={setPlotType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    mapInstance={mapInstance}
                                    scanLoading={scanLoading}
                                    setScanLoading={setScanLoading}
                                    blockScanMap={blockScanMap}
                                    entity={entity}
                                    setProgress={setProgress}
                                    progress={progress}
                                    stats={stats}
                                    setCurrentScanId={setCurrentScanId}
                                    blockName={blockName}
                                    setBlockName={setBlockName}
                                    viewMode={viewMode}
                                    setViewMode={setViewMode}
                                    currentScanId={currentScanId}
                                    addToSidebar={addToSidebar}
                                    polygonStats={polygonStats}
                                    stageType={stageType}
                                    blockInfo={blockInfo}
                                    setBlockInfo={setBlockInfo}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSectionGeojson={setSectionGeojson}
                                    setPolygonStats={setPolygonStats}
                                    polygonStatDialog={polygonStatDialog}
                                    setPolygonStatDialog={setPolygonStatDialog}
                                    setAddToSidebar={setAddToSidebar}
                                    isLoad={isLoad}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                    controlEnabled={controlEnabled}
                                    initialLocation={initialLocation}
                                    treeData={treeData}
                                    emitterData={emitterData}
                                    setControlEnabled={setControlEnabled}
                                    setBlockData={setBlockData}
                                    adminUser={adminUser}
                                    treeViewLat={treeViewLat}
                                    treeViewLong={treeViewLong}
                                    sectionGeojson={sectionGeojson}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                    scanInfo={scanInfo}
                                    fruitType={fruitType}
                                    qualifyingBlocks={qualifyingBlocks}
                                    setScanInfo={setScanInfo}
                                    setInitialLocation={setInitialLocation}
                                />
                            }
                        />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </MapStateProvider>
                <Snackbar 
                    open={!!error} 
                    autoHideDuration={8000} 
                    onClose={() => setError(null)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert 
                        onClose={() => setError(null)} 
                        severity="error" 
                        variant="filled"
                        className="w-full"
                    >
                        {error}
                    </Alert>
                </Snackbar>
                </Router>
            </div>
        );
    } else {
        return (
            <div className="bg-orchardGreen font-lato grid h-screen flex flex-col place-items-center" data-testid="login-container">
                <Login />
            </div>
        );
    }
}

export default App;