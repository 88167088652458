import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const CalibratedStatsToggle = ({ viewCalibratedStats, setViewCalibratedStats }) => {

    const handleChange = (event) => {
        setViewCalibratedStats(event.target.checked);
    };
    
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={viewCalibratedStats}
                    onChange={handleChange}
                />
            }
            label={"View Calibrated Stats"}
        />
    );
};

export default CalibratedStatsToggle;