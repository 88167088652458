import { useState } from 'react';
import { entityTypeConfig } from '../constants/entityTypeConfig';
import { useMapState } from '../context/MapStateContext';
import { MetricComponent } from './ScanViewStats';
import CalibratedStatsToggle from './toggles/CalibratedStatsToggle.js';
import UnitToggle from './toggles/UnitToggle.js';

const isMalformed = (value) => {
    return value === null || value === undefined || Number.isNaN(value) || value === '' || value === 0 || value === -1;
};

export const PolygonStats = ({ entityType, stats, stageType }) => {
    const { isImperial, setIsImperial } = useMapState();
    const hasCalibrations = stats.total_fruit_calibrated > 0;
    const [viewCalibratedStats, setViewCalibratedStats] = useState(hasCalibrations)
    const [expandAll, setExpandAll] = useState(false);
    const entityConfig = entityTypeConfig(isImperial, stageType, false, viewCalibratedStats).metrics || []; 

    return (
        <>
            <p className="text-xs text-gray-500 cursor-pointer hover:text-gray-700 text-right" onClick={() => setExpandAll(!expandAll)}>
                {expandAll ? 'Collapse All' : 'Expand All'}
            </p>
            {entityConfig.map(metricConfig => {
                const metricValue = stats[metricConfig.metricPath];
                if (isMalformed(metricValue)) {
                    return null;
                }

                return (
                    <MetricComponent
                        key={metricConfig.key} 
                        config={metricConfig}
                        data={stats}
                        expandAll={expandAll}
                    />
                );
            })}

            <UnitToggle isImperial={isImperial} setIsImperial={setIsImperial} />
            {hasCalibrations && <CalibratedStatsToggle viewCalibratedStats={viewCalibratedStats} setViewCalibratedStats={setViewCalibratedStats} />}


        </>
    );
}