import { Bar, BarChart, CartesianGrid, Cell, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export const PackoutChart = ({ data, targetedPackSizes, setTargetedPackSizes }) => {
  const chartData = Object.entries(data).map(([size, value]) => ({ size, value }));

  const handleBarClick = (entry) => {
    const sizeAsNumber = Number(entry.size);
    setTargetedPackSizes(prev => ({
      ...prev,
      [sizeAsNumber]: !prev[sizeAsNumber]
    }));
  };

  return (
    <div className="relative w-full h-full cursor-pointer">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData} margin={{ left: 4, bottom: 15, top: 30 }} onClick={(data) => {
          if (data && data.activePayload) {
            handleBarClick(data.activePayload[0].payload);
          }
        }}>
          <text
            x={300}
            textAnchor="middle"
            dominantBaseline="hanging"
            fill="#333"
            fontSize="16"
            fontWeight="bold"
          >
            Estimated Packout Distribution
          </text>
          <text
            x={300}
            y={17}
            textAnchor="middle"
            dominantBaseline="hanging"
            fill="#666"
            fontSize="12"
          >
            Click a bar to omit from bin estimations
          </text>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="size">
            <Label value="Pack Size" position="insideBottom" offset={-8} />
          </XAxis>
          <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
          <Tooltip 
            formatter={(value, name) => [`${value}%`, 'Value']}
            labelFormatter={(label) => `${label}`}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white p-2 border border-gray-200 rounded shadow">
                    <p className="font-medium">
                      {label}  <span className="text-[#0000FF] ml-auto">{Number(payload[0].value).toFixed(1)}%</span>
                    </p>
                    <p className="text-sm text-gray-600">
                      {targetedPackSizes[label] 
                        ? "Click to exclude from bin estimations"
                        : "Click to include in bin estimations"
                      }
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Bar 
            dataKey="value" 
            fill="#0000FF"
          >
            <LabelList dataKey="value" position="top" formatter={(value) => `${Number(value).toFixed(1)}%`} />
            {chartData.map((entry) => (
              <Cell 
                key={entry.size}
                fill={targetedPackSizes[entry.size] ? '#0000FF' : '#808080'} 
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};