import { useCallback } from 'react';
import { trackEvent } from '../firebase';

export const useTrackEvent = () => {
  const trackUserEvent = useCallback((eventName, customParams = {}) => {
    // Get user context if available (like current user ID)
    const currentUser = window.firebase?.auth()?.currentUser;
    
    // Combine parameters with useful defaults
    const params = {
      ...customParams,
      timestamp: new Date().toISOString(),
      user_id: currentUser?.uid || 'anonymous',
      user_email: currentUser?.email,
      // Add URL path for context
      path: window.location.pathname,
    };

    // Log event to Firebase
    trackEvent(eventName, params);
    
    // // For debugging
    // if (process.env.NODE_ENV === 'development') {
    //   console.debug(`[Event Tracked] ${eventName}`, params);
    // }
    
    return true; // For easier chaining with other functions
  }, []);

  return trackUserEvent;
};

export default useTrackEvent; 