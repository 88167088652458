import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faGear, faSquareMinus, faSquarePlus, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormControlLabel
} from '@mui/material';
import Switch from '@mui/material/Switch';
import convert from 'convert-units';
import { useEffect, useState } from 'react';
import { EntityType } from '../common/types';
import { useMapState } from '../context/MapStateContext';
import useTrackEvent from '../hooks/useTrackEvent';
import { getTerminology } from '../i18n';

export const EnableDrawControl = ({ drawEnabled, setDrawEnabled }) => {
    return (
        <div
            className='flex items-center px-2 py-2 font-mono bg-white text-xs cursor-pointer text-black border border-gray-900 rounded-lg'
            onClick={() => {
                setDrawEnabled((b) => !b);
            }}
        >
            <div className='flex-shrink-0'>
                <FontAwesomeIcon icon={'fa-solid fa-vector-square'} size='xl' fixedWidth />
            </div>
            <div className='ml-1'>
                Area Selection
                <br />
                {drawEnabled && (
                    <span className='font-mono text-gray-600'>Click the first node to confirm selection. Press delete to cancel selection</span>
                )}
            </div>
        </div>
    );

}

export const MapSettings = ({
    entityType,
    plotType,
    setPlotType,
    controlEnabled,
    setLowerBound,
    setUpperBound,
    adminUser,
    viewTapeSections,
    setViewTapeSections,
    viewZoneMap,
    setViewZoneMap,
    sectionGeojson,
    stageType,
    handlePregenerate,
    fruitType,
    stats,
    setIsGroundTruthDialogOpen,
}) => {
    const { plotConfigs, customTarg, setCustomTarg, setIsTaskMapDialogOpen } = useMapState();
    const trackUserEvent = useTrackEvent();
    const [visible, setVisible] = useState(true);
    const [activePopup, setActivePopup] = useState(null);
    const [expandedOption, setExpandedOption] = useState(null);
    const hasValidNormalizationStats = !!(stats && (stats.avg_tree_diam_in || stats.avg_canopy_area_m2));

    useEffect(() => {
        if (entityType === 'trees') {
            setPlotType('tree_diam')
            setLowerBound(80)
            setUpperBound(120)
        }
        if (entityType === 'fruits') {
            setPlotType('uniform')
            setLowerBound(60)
            setUpperBound(140)
        }
    }, [entityType])

    const toggleVisible = () => {
        setVisible(v => !v);
    };

    const toggleOptionExpand = (optionValue) => {
        setExpandedOption(expandedOption === optionValue ? null : optionValue);
    };

    const handleChange = (event) => {
        if (controlEnabled) {
            const value = event.target.value;
            const config = plotConfigs[value];
            if (value === 'color') {
                let lower = (stats.avg_hue + config?.bounds?.[0] + 360) % 180;
                let upper = (stats.avg_hue + config?.bounds?.[1] + 360) % 180;
                if (lower > upper) {
                    upper += 180;
                }
                setLowerBound(lower);
                setUpperBound(upper);
            }
            else if (value === 'canopy_hue') {
                setLowerBound((stats.avg_canopy_hue + config?.bounds?.[0] + 360) % 180);
                setUpperBound((stats.avg_canopy_hue + config?.bounds?.[1] + 360) % 180);
            }
            else {
                setLowerBound(config?.bounds?.[0] ?? 80);
                setUpperBound(config?.bounds?.[1] ?? 120);
            }
            setPlotType(value);
            setExpandedOption(null);
        }
    };

    const handleMouseEnter = (event, id) => {
        setActivePopup(id);
        event.currentTarget.style.position = "relative";
    };

    const handleMouseLeave = (event) => {
        setActivePopup(null);
        event.currentTarget.style.position = "";
    };

    const getVisualizationConfig = (entityType, stageType, fruitType, adminUser, stats) => {
        const exclusions = [];

        // Check for the presence of specific fields and update the exclusion array
        // TODO: make this a part of plotConfigs, as well as fruitType 
        if (!stats?.avg_hue) {
            exclusions.push('color');
        }
        if (!stats?.avg_canopy_area_m2) {
            exclusions.push('tree-vigor');
        }
        if (stats && !stats.ccsa_red_m2 && !stats.ccsa_yellowred_m2 && !stats.ccsa_yellow_m2 && !stats.ccsa_wilted_m2) {
            exclusions.push('rld');
        }
        if (!stats?.avg_tree_diam_in){
            exclusions.push('tree_diam');
        }
        if (!stats?.avg_trunk_xsarea){
            exclusions.push('tree_area');
        }
        if (!stats?.avg_tree_spacing_ft){
            exclusions.push('spacing');
        }
        if (stats && !stats.avg_canopy_hue) {
            exclusions.push('canopy_hue');
        }
        if (stats && !stats.total_emitters_detected) {
            exclusions.push('emitters');
        }
        if (stats && !stats.avg_trunk_height_m) {
            exclusions.push('trunk_height');
        }

        return Object.entries(plotConfigs)
            .filter(([value, config]) => {
                const isCorrectEntityType = !config.entityType || config.entityType === entityType;
                const isCorrectStageType = !config.stageType || config.stageType.includes(stageType);
                const isAdminAllowed = !config.adminOnly || (config.adminOnly && adminUser);
                const isNotExcluded = !exclusions.includes(value);
                return isCorrectEntityType && isCorrectStageType && isAdminAllowed && isNotExcluded;
            })
            .map(([value, config]) => ({
                value,
                label: getTerminology(config.label),
                description: getTerminology(config.description)
            }));
    };

    const handleToggleTapeSections = (event) => {
        setViewTapeSections(event.target.checked);
    };

    const handleViewZoneMap = (event) => {
        setViewZoneMap(event.target.checked);
    };

    const handleTaskMapClick = () => {
        trackUserEvent('zone_export_icon_click', {
            entity_type: entityType,
            plot_type: plotType,
            stage_type: stageType
        });
        setIsTaskMapDialogOpen(true);
    };


    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'g') {
                event.preventDefault();
                event.stopImmediatePropagation();
                setViewTapeSections(prev => !prev);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='font-lato bg-white opacity-80 rounded-md m-2 px-2 pb-2 pt-1 fixed right-0 top-0 text-lg drop-shadow-md flex flex-row'>
            {visible ? (
                <div className='w-60'>
                    <div className='font-bold flex justify-center items-center'>
                        <span className="flex-grow text-center">Heatmap Type</span>
                        <FontAwesomeIcon className='text-2xl align-middle cursor-pointer' icon={faSquareMinus} onClick={toggleVisible} />
                    </div>
                    <hr />
                    <form>
                        {getVisualizationConfig(entityType, stageType, fruitType, adminUser, stats).map((e, index) => (
                            <div key={e.value}>
                                <label className='flex flex-row items-center justify-between' 
                                    onMouseEnter={(event) => handleMouseEnter(event, index)}
                                    onMouseLeave={handleMouseLeave}>
                                    <div className="flex items-center">
                                        <input 
                                            type='radio' 
                                            value={e.value} 
                                            checked={plotType === e.value} 
                                            onChange={handleChange} 
                                            disabled={!controlEnabled} 
                                        />
                                        <span className='ml-2'>{e.label}</span>
                                    </div>
                                    {e.value === 'uniform' && hasValidNormalizationStats && (
                                        <FontAwesomeIcon 
                                            className='cursor-pointer' 
                                        icon={expandedOption === 'uniform' ? faChevronUp : faChevronDown} 
                                        onClick={() => toggleOptionExpand('uniform')}
                                        />
                                    )}
                                    {activePopup === index && (
                                        <div className="absolute z-10 right-full right-0 mr-2 w-48 rounded-lg shadow-lg bg-white border border-gray-200">
                                            <div className="py-2 px-4 text-sm">{e.description}</div>
                                        </div>
                                    )}
                                </label>

                                {/* GAP has good bud scans to test for this feature */}
                                {e.value === 'uniform' && expandedOption === 'uniform' && (
                                    <div className="ml-6 text-sm p-1 rounded">
                                        <div className="flex flex-col">
                                            <div className="font-semibold">Apply Normalization:</div>
                                            <div className="flex space-x-2">
                                                    <label className="flex items-center space-x-1 cursor-pointer">
                                                        <input
                                                            type="radio"
                                                            name="normalization"
                                                            checked={customTarg.normalizationFeature === null}
                                                            onChange={() => setCustomTarg({...customTarg, normalizationValue: null, normalizationFeature: null, normalizationUnit: null})}
                                                        />
                                                        <span>None</span>
                                                    </label>
                                                    {stats.avg_tree_diam_in && ( <label className="flex items-center space-x-1 cursor-pointer">
                                                        <input
                                                            type="radio"
                                                            name="normalization"
                                                            checked={customTarg.normalizationFeature === 'xs_area_in2'}
                                                            onChange={() => setCustomTarg({...customTarg, normalizationValue: convert(stats.avg_trunk_xsarea).from('cm2').to('in2'), normalizationFeature: 'xs_area_in2', normalizationUnit: 'per in² TCSA'})}
                                                        />
                                                        <span>/ TCSA (in<sup>2</sup>)</span>
                                                    </label>)}
                                                    {/* {stats.avg_canopy_area_m2 && ( <label className="flex items-center space-x-1 cursor-pointer">
                                                        <input
                                                            type="radio"
                                                            name="normalization"
                                                            checked={customTarg.normalizationFeature === 'canopy_area_m2'}
                                                            onChange={() => setCustomTarg({...customTarg, normalizationValue: stats.avg_canopy_area_m2, normalizationFeature: 'canopy_area_m2', normalizationUnit: 'per m² CCSA'})}
                                                        />
                                                        <span>/ CCSA</span>
                                                    </label>)} */}
                                                </div>
                                            </div>
                                        </div>
                                )}
                            </div>
                        ))}
                    </form>
                    {adminUser && (
                        <button style={{ maxWidth: '350px' }}
                            className="btn-primary bg-green-500 hover:bg-green-700"
                            onClick={(e) => handlePregenerate()}
                        >
                            Pregenerate Scan (admin)
                        </button>
                    )}
                    <hr />
                    {entityType === EntityType.Fruits && sectionGeojson && sectionGeojson.features.length > 0 && (
                        <div className="flex items-center justify-between">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={viewTapeSections}
                                        onChange={handleToggleTapeSections}
                                        className="m-0"
                                    />
                                }
                                label="Ground Truth Sections"
                            />
                            <FontAwesomeIcon 
                                icon={faGear} 
                                className="cursor-pointer ml-2" 
                                onClick={() => setIsGroundTruthDialogOpen(true)}
                            />
                        </div>
                    )}

                    {plotConfigs[plotType]?.enableZone && (
                        <div className="flex items-center justify-between">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={viewZoneMap}
                                        onChange={handleViewZoneMap}
                                        size="medium"
                                        className="m-0"
                                    />
                                }
                                label={"View Zone Mode"}
                            />
                            <FontAwesomeIcon 
                                icon={faUpRightFromSquare} 
                                className="cursor-pointer ml-2" 
                                onClick={handleTaskMapClick}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon className='text-2xl align-middle cursor-pointer' icon={faSquarePlus} onClick={toggleVisible} />
                </div>
            )}
        </div>
    );
};
