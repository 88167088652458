import { differenceInDays, format } from 'date-fns';
import { useMemo } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export const VolumeChart = ({ data, linearRegression, renderLineOfBestFit = true }) => {

  const regressionLine = useMemo(() => {
    if (!linearRegression) return [];

    return data.map(({ date }) => {
      const daysSinceStart = differenceInDays(date, data[0].date);
      const [, predictedVolume] = linearRegression.predict(daysSinceStart);
      return { date, regression: predictedVolume };
    });
  }, [linearRegression, data]);

  const dateFormatter = (date) => {
    return format(new Date(date), "MMM d");
  };

  const domain = [
    dataMin => new Date(dataMin),
    dataMax => new Date(dataMax)
  ];

  return (
    <>
      <ResponsiveContainer height={400}>
        <LineChart
          margin={{ left: 30, top: 30 }}
        >
          <text
            x={300}
            textAnchor="middle"
            dominantBaseline="hanging"
            fill="#333"
            fontSize="16"
            fontWeight="bold"
          >
            Fruit Volume Over Time
          </text>
          <XAxis
            dataKey="date"
            scale="time"
            type="number"
            domain={domain}
            tickFormatter={dateFormatter}
          />
          <YAxis
            tickFormatter={(value) => value >= 1000 ? value.toLocaleString() : value}
            label={{ value: 'Volume (mm³)', angle: -90, position: 'insideLeft', offset: -25 }}
          />
          <Tooltip
            labelFormatter={dateFormatter}
            formatter={(value, name) => [
              `${Math.round(value).toLocaleString()} mm³`,
              name === 'Measured Volume' ? 'Measured Volume' : 'Line of Best Fit'
            ]}
          />
          <Legend />
          <Line data={data} type="linear" dataKey="volume" name="Measured Volume" stroke="#1E90FF" dot={{ r: 4 }} activeDot={{ r: 8 }} />
          {renderLineOfBestFit && <Line data={regressionLine} type="linear" dataKey="regression" name="Line of Best Fit" stroke="#808080" strokeDasharray="3 3" dot={false} />}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};