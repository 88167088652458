// constants.js
export const MEDIAN_TRUNK_XS_AREA= "median_trunk_xsarea";
export const AVG_TRUNK_XSAREA = "avg_trunk_xsarea";
export const TOTAL_FRUITS_DETECTED = "total_fruit_detected";
export const TOTAL_FRUITS_CALIBRATED = "total_fruit_calibrated";
export const TOTAL_TREES = "total_trees";
export const TOTAL_EMITTERS_DETECTED = "total_emitters_detected";
export const PERCENT_TREES = "percent_trees";
export const FRUITS_PER_TREE_DETECTED = "fruit_per_tree_detected";
export const FRUITS_PER_TREE_CALIBRATED = "fruit_per_tree_calibrated";
export const CALIBRATION_RATIO = "calibration_ratio";
export const AVG_FRUIT_VOL = "avg_fruit_vol";
export const AVG_HUE = "avg_hue";
export const AVG_COVERAGE = "avg_coverage";
export const AVG_FRUIT_DIAM = "avg_fruit_diam";
export const AVG_FRUITLET_DIAM = "avg_fruitlet_diam";
export const AVG_FRUIT_WEIGHT = "avg_fruit_weight";
export const STAGE_TYPE = "entity_type";
export const RUN_MASTER = "run_master";
export const VARIETY = "variety_type";
export const PACK_SIZE = "pack_size";
export const BLOCK_SCAN_MAP = "block_scan_map";
export const ADDRESS = "address";
export const PLANTED_TREES = "planted_trees";
export const BLOCK_ID = 'block_id'
export const AVG_TREE_DIAM_IN = 'avg_tree_diam_in'
export const MEDIAN_TREE_DIAM_IN = 'median_tree_diam_in'
export const AVG_TREE_SPACING_FT = 'avg_tree_spacing_ft'
export const AVG_TREE_AREA_IN2 = 'avg_tree_area_in2'
export const AVG_CANOPY_HEIGHT_M = 'avg_canopy_height_m'
export const AVG_CANOPY_AREA_M2 = 'avg_canopy_area_m2'
export const STD_FRUITS_PER_TREE = 'std_fruits_per_tree'
export const STD_FRUIT_DIAM = 'std_fruit_diam'
export const Q25_FRUIT_DIAM = 'q25_fruit_diam'
export const Q75_FRUIT_DIAM = 'q75_fruit_diam'
export const Q25_FRUITS_PER_TREE = 'q25_fruits_per_tree'
export const Q75_FRUITS_PER_TREE = 'q75_fruits_per_tree'
export const STD_FRUITS_PER_TREE_CALIBRATED = 'std_fruits_per_tree_calibrated'
export const Q25_FRUITS_PER_TREE_CALIBRATED = 'q25_fruits_per_tree_calibrated'
export const Q75_FRUITS_PER_TREE_CALIBRATED = 'q75_fruits_per_tree_calibrated'
export const STD_TREE_DIAM_IN = 'std_tree_diam_in'
export const STD_TREE_AREA_IN2 = 'std_trunk_xsarea'
export const STD_CANOPY_HEIGHT_M = 'std_canopy_height_m'
export const STD_CANOPY_AREA_M2 = 'std_canopy_area_m2'
export const P25_TREE_DIAM_IN = 'p25_tree_diam_in'
export const P75_TREE_DIAM_IN = 'p75_tree_diam_in'
export const P25_TREE_AREA_CM2 = 'p25_trunk_xsarea'
export const P75_TREE_AREA_CM2 = 'p75_trunk_xsarea'
export const P25_CANOPY_HEIGHT_M = 'p25_canopy_height_m'
export const P75_CANOPY_HEIGHT_M = 'p75_canopy_height_m'
export const P25_CANOPY_AREA_M2 = 'p25_canopy_area_m2'
export const P75_CANOPY_AREA_M2 = 'p75_canopy_area_m2'
export const MEDIAN_TRUNK_HEIGHT_M = 'median_trunk_height_m';
export const AVG_TRUNK_HEIGHT_M = 'avg_trunk_height_m';
export const STD_TRUNK_HEIGHT_M = 'std_trunk_height_m';
export const P25_TRUNK_HEIGHT_M = 'p25_trunk_height_m';
export const P75_TRUNK_HEIGHT_M = 'p75_trunk_height_m';
export const NUM_MISSING_TRUNKS = 'missing_trunks';
export const NUM_VALID_TRUNK_SLOTS = 'num_valid_trunk_slots';
export const PERCENT_MISSING_TRUNKS = 'percent_missing_trunks';

// Estimated constants
export const PERCENT_TREES_ESTIMATED = "percent_trees_estimated";
export const TOTAL_TREES_ESTIMATED = "total_trees_estimated";
export const TOTAL_FRUITS_DETECTED_ESTIMATED = "total_fruit_detected_estimated";
export const TOTAL_FRUITS_CALIBRATED_ESTIMATED = "total_fruit_calibrated_estimated";
export const TOTAL_YIELD_ESTIMATED = "total_yield_estimated";

export const pricing_data_date = "10/27/24"

export const APPLE_BOX_STANDARD_LBS = 40;
  
// From WSTFA - Non-organic Bulletin
export const applePricesPerPound = {
  "Red Delicious": 0.45,
  "Golden Delicious": 0.71,
  "Granny Smith": 0.60,
  "Fuji": 0.63,
  "Gala": 0.58,
  "Envy": 1.03,
  "Ambrosia": 0.57,
  "Pink Lady": 0.61,
  "Honeycrisp": 0.89,
  "Autumn Glory": 0.58,
  "Jonagold": 0.55
};

// From WSTFA - Organic  Bulletin
export const organicApplePricesPerPound = {
  "Granny Smith": 0.82,
  "Fuji": 0.84,
  "Gala": 0.80,
  "Envy": 1.23,
  "Ambrosia": 0.99,
  "Pink Lady": 0.93,
  "Honeycrisp": 1.32
};

// From WSTFA - Non-organic Bulletin
export const appleBoxPricesByPackSize = {
  "Fuji": {
    "48": 27.76,
    "56": 27.76,
    "64": 27.76,
    "72": 27.76,
    "80": 25.04,
    "88": 25.04,
    "100": 22.64,
    "113": 23.37,
    "125": 26.97,
    "138": 25.56,
    "150": 25.56,
  },
  "Gala": {
    "48": 26.37,
    "56": 26.37,
    "64": 26.37,
    "72": 26.37,
    "80": 24.29,
    "88": 24.29,
    "100": 23.61,
    "113": 22.28,
    "125": 21.15,
    "138": 21.15,
    "150": 21.15,
  },
  "Golden Delicious": {
    "48": 30.81,
    "56": 30.81,
    "64": 30.81,
    "72": 30.81,
    "80": 29.92,
    "88": 29.92,
    "100": 25.70,
    "113": 23.63,
    "125": 18.78,
    "138": 19.33,
    "150": 19.33,
  },
  "Granny Smith": {
    "48": 23.75,
    "56": 23.75,
    "64": 23.75,
    "72": 23.75,
    "80": 24.18,
    "88": 24.18,
    "100": 24.82,
    "113": 24.64,
    "125": 22.20,
    "138": 23.53,
    "150": 23.53,
  },
  "Honeycrisp": {
    "48": 38.92,
    "56": 38.92,
    "64": 38.92,
    "72": 38.92,
    "80": 38.46,
    "88": 38.46,
    "100": 24.55,
    "113": 20.31,
    "125": 41.63,
    "138": 37.75,
    "150": 37.75,
  },
  "Red Delicious": {
    "48": 18.96,
    "56": 18.96,
    "64": 18.96,
    "72": 18.96,
    "80": 17.28,
    "88": 17.28,
    "100": 16.90,
    "113": 17.78,
    "125": 18.14,
    "138": 18.05,
    "150": 18.05,
  },
  "Pink Lady": {
    "48": 29.77,
    "56": 29.77,
    "64": 29.77,
    "72": 29.77,
    "80": 30.66,
    "88": 30.66,
    "100": 23.70,
    "113": 14.77,
    "125": 20.28,
    "138": 24.16,
    "150": 24.16,
  }
};