import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import UploadIcon from '@mui/icons-material/Upload';
import { Button, Checkbox, DialogActions, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { useTrackEvent } from '../hooks/useTrackEvent';
import logo from '../assets/logo.svg';

import EnhancedTable from '../SelectTable';


/**
 * Parses a scan string into its components based on two valid schemas:
 * 1. orchardCode_scanName_timeStamp (e.g., CPI_0005_2024-03-25T10:25:52)
 * 2. orchardCode_cameraName_scanName (e.g., CPI_avatar_0005)
 * 
 * @param {string} scanStr - The scan string to parse
 * @returns {Object|null} Object with properties orchardCode, cameraName, scanName, timeStamp, or null if invalid
 */
// TODO: This shouldn't be needed as long as the scanInfo prop is populated correctly
export const parseScanStr = (scanStr) => {
    try {
        const parts = scanStr.split('_');
        
        if (parts.length < 2 || !/^[a-zA-Z]+$/.test(parts[0])) {
            return null;
        }
        
        // Handle old schema: orchardCode_scanName_timeStamp
        if (parts.length === 3 && parts[2].includes('T') && parts[2].includes(':')) {
            try {
                // Validate timestamp format
                if (isNaN(Date.parse(parts[2]))) {
                    return null;
                }
                return {
                    orchardCode: parts[0],
                    cameraName: null,
                    scanName: parts[1],
                    timeStamp: parts[2]
                };
            } catch (error) {
                return null;
            }
        }
        
        // Handle new schema: orchardCode_cameraName_scanName
        else if (parts.length === 3) {
            return {
                orchardCode: parts[0],
                cameraName: parts[1],
                scanName: parts[1] + '_' + parts[2],
                timeStamp: null
            };
        }
        
        return null;
        
    } catch (error) {
        return null;
    }
};

// Migrating to MUI v5
const CustomTextField = withStyles(
    TextField,
    (theme) => ({
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'black',
            },
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', // Background color
                '& fieldset': {
                    borderColor: 'black',
                },
                '&:hover fieldset': {
                    borderColor: 'black',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'black',
                },
            },
            '& .MuiInputBase-input': {
                color: 'black', // Text color
            },
            '& .MuiOutlinedInput-input': {
                color: 'black', // Input text color
            },
        }
    }),
);


export const PasswordForm = ({ onPasswordCheck }) => {
    const [password, setPassword] = useState("");

    const handleSubmit = event => {
        event.preventDefault();
        onPasswordCheck(password);
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato h-screen bg-orchardGreen">
            <form onSubmit={handleSubmit} className="flex flex-col m-2 items-center">
                <CustomTextField
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    label="Enter password"
                    variant="outlined"
                    className="mb-4"
                />
                <br></br>
                <button style={{ maxWidth: '350px' }}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleSubmit}
                >Submit</button>
            </form>
        </div>
    );
};

export const AdminOptions = () => {
    const [selectedScans, setSelectedScans] = useState(new Set());

    const handleBack = (e) => {
        e.preventDefault();
        window.location.pathname = '/'
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato bg-orchardGreen" style={{ minHeight: "100vh" }}>
            <img className="w-40 h-40 mb-4" src={logo} alt="Orchard Robotics Logo" />
            <p className="font-bold text-3xl text-center text-white mb-4">Admin Mode</p>
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel0-content"
                        id="panel0-header"
                    >
                        <Typography>Ask the database</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SQLQueryInterface />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Scan Status</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ScanPortal selectedScans={selectedScans} setSelectedScans={setSelectedScans} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Add/Remove Scans</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ScanForm selectedScans={selectedScans} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Add/Remove Users</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <ManageUsers />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Offset Trunk Scan</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <OffsetScan />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Create Tape Sections</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CalibrationSectionManager />
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Manual Counts</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <ManualCountManager/>
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}


                <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2"
                    onClick={handleBack}
                >
                    Back to FruitScope
                </button>
            </div>
        </div>
    );
};

export const CalibrationSectionManager = () => {
    const [lon, setLon] = useState("");
    const [lat, setLat] = useState("");
    const [sectionCode, setSectionCode] = useState("");
    const [bulkIngestText, setBulkIngestText] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const handleDelete = (e) => {
        e.preventDefault();
        setDialogOpen(true);
        setDialogMessage("Deletion running!");
        axios.post('/calib/delete_calibration_section', {
            section_code: sectionCode,
        }).then(res => {
            console.log(res.data);
            setDialogMessage("Deletion run successfully!");
        }).catch(error => {
            setDialogMessage("Delete failed! :(");
            console.error(error);
        });
    };

    const handleUpload = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Create running!");
        axios.post('/calib/manual_calibration_section', {
            lon: lon,
            lat: lat,
            section_code: sectionCode,
        }).then(res => {
            console.log(res.data);
            setDialogMessage("Created/updated successfully!");
        }).catch(error => {
            setDialogMessage("Update failed! :(");
            console.error(error);
        });
    };



    const handleBulkUpload = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Create running!");
        let errorMessage = "";
        const sectionRequests = [];
        const lines = bulkIngestText.split('\n');
        for (const line of lines) {
            const lineParts = line.split(' ');
            if (lineParts.length !== 3) {
                continue;
            }
            const [sectionCodePart, latPart, lonPart] = lineParts;
            const sectionRequest = axios.post('/calib/manual_calibration_section', {
                lon: lonPart,
                lat: latPart,
                section_code: sectionCodePart,
            }).catch(error => {
                console.log(errorMessage);
                errorMessage += `\n${line} - ${error}`;
                throw error;
            });
            sectionRequests.push(sectionRequest);
        }
        Promise.allSettled(sectionRequests).then(res => {
            console.log(res.data);
            // setUploadDialogOpen(false);
            if (errorMessage !== '') {
                setDialogMessage(`Update failed! :(\n${errorMessage}`);
            } else {
                setDialogMessage(`Created/updated successfully!`);
            }
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadDialogOpen(false);
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">Specify section code and gps info to create a calibration (tape) section.</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={sectionCode}
                        onChange={e => setSectionCode(e.target.value)}
                        label="Section Code (eg. 8123456)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={lat}
                        onChange={e => setLat(e.target.value)}
                        label="Center Latitude of section"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={lon}
                        onChange={e => setLon(e.target.value)}
                        label="Center Longitide of section"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                </div>

                {/* <Button type="submit" variant="outline" color="primary">
                    Submit
                </Button> */}
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleUpload}
                    >Upload</button>
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleDelete}
                    >Delete</button>
                </div>

                <div className="flex space-x-2 w-full mt-10">
                    <TextField
                        type="text"
                        value={bulkIngestText}
                        label="List of sections and gps coords one per line (8000196 46.90433 -119.50842)"
                        multiline
                        onChange={e => setBulkIngestText(e.target.value)}
                        className="w-full"
                    />
                </div>
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleBulkUpload}
                    >Bulk Upload</button>
                    {/* <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleDelete}
                    >Delete</button> */}
                </div>
            </form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={uploadDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
        </div>
    );
};

export const ManualCountManager = () => {
    const [count, setCount] = useState("");
    const [timestamp, setTimestamp] = useState("");
    const [sectionCode, setSectionCode] = useState("");
    const [bulkIngestText, setBulkIngestText] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");


    const handleUpload = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Create running!");
        axios.post('/calib/manual_count', {}, {params: {
                count: count,
                section_code: sectionCode,
                timestamp: timestamp,
            }}).then(res => {
            console.log(res.data);
            // setUploadDialogOpen(false);
            setDialogMessage("Created/updated successfully!");
        }).catch(error => {
            setDialogMessage("Update failed! :(");
            console.error(error);
        });
    };



    const handleBulkUpload = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Create running!");
        let errorMessage = "";
        const sectionRequests = [];
        const lines = bulkIngestText.split('\n');
        for (const line of lines) {
            const lineParts = line.split(' ');
            if (lineParts.length !== 3) {
                continue;
            }
            const [sectionCodePart, countPart, timestampPart] = lineParts;
            const sectionRequest = axios.post('/calib/manual_count', {}, {params: {
                    count: countPart,
                    timestamp: timestampPart,
                    section_code: sectionCodePart,
                }}).catch(error => {
                console.log(errorMessage);
                errorMessage += `\n${line} - ${error}`;
                throw error;
            });
            sectionRequests.push(sectionRequest);
        }
        Promise.allSettled(sectionRequests).then(res => {
            console.log(res.data);
            // setUploadDialogOpen(false);
            if (errorMessage !== '') {
                setDialogMessage(`Update failed! :(\n${errorMessage}`);
            } else {
                setDialogMessage(`Created/updated successfully!`);
            }
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadDialogOpen(false);
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">Specify section code and count to add a manual count.</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={sectionCode}
                        onChange={e => setSectionCode(e.target.value)}
                        label="Section Code (eg. 8123456)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={count}
                        onChange={e => setCount(e.target.value)}
                        label="Manual Count (eg. 102)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={timestamp}
                        onChange={e => setTimestamp(e.target.value)}
                        label="Timestamp of measurement (eg. 2024-05-12) leave blank for now"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                </div>

                {/* <Button type="submit" variant="outline" color="primary">
                    Submit
                </Button> */}
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleUpload}
                    >Upload</button>
                    {/* <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleDelete}
                    >Delete</button> */}
                </div>

                <div className="flex space-x-2 w-full mt-10">
                    <TextField
                        type="text"
                        value={bulkIngestText}
                        label="List of sections and gps coords one per line (8000196 102 2024-05-12)"
                        multiline
                        onChange={e => setBulkIngestText(e.target.value)}
                        className="w-full"
                    />
                </div>
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleBulkUpload}
                    >Bulk Upload</button>
                    {/* <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleDelete}
                    >Delete</button> */}
                </div>
            </form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={uploadDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
        </div>
    );
};

export const ManageUsers = () => {
    const [orchardName, setOrchardName] = useState("");
    const [userFirebaseID, setUserFirebaseID] = useState("");
    const [userName, setUserName] = useState("");
    const [hasAdmin, setHasAdmin] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const handleDelete = (e) => {
        e.preventDefault();
        setDialogOpen(true);
        setDialogMessage("Deletion running!");
        axios.post('/users/delete', {
            firebase_id: userFirebaseID,
        }).then(res => {
            console.log(res.data);
            // setDialogOpen(false);
            setDialogMessage("Deletion run successfully!");
        }).catch(error => {
            setDialogMessage("Delete failed! :(");
            console.error(error);
        });
    };

    const handleUpload = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Update running!");
        axios.post('/admin/user/create', {
            orchard_name: orchardName,
            user_name: userName,
            user_id: userFirebaseID,
            admin: hasAdmin,
        }).then(res => {
            console.log(res.data);
            // setUploadDialogOpen(false);
            setDialogMessage("Created/updated successfully!");
        }).catch(error => {
            setDialogMessage("Update failed! :(");
            console.error(error);
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadDialogOpen(false);
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">Specify orchard code and user info to delete or update user.</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={orchardName}
                        onChange={e => setOrchardName(e.target.value)}
                        label="Orchard (eg. CHI)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        label="User Name (eg. John Johnson)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={userFirebaseID}
                        onChange={e => setUserFirebaseID(e.target.value)}
                        label="User Firebase ID (eg. D67cxfgvb5GHhSFdgfU)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={hasAdmin}
                            onChange={e => setHasAdmin(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Is User Admin" />
                </div>
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleUpload}
                    >Upload</button>
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleDelete}
                    >Delete</button>
                </div>
            </form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={uploadDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
        </div>
    );
};

export const UploadOptions = ({orchardName, setOrchardName, scanNumber, setScanNumber, handleConfirm, handleCancel}) => {
    const [numSubrows, setNumSubrows] = useState("");
    const [forceColinear, setForceColinear] = useState(false);
    const [createBlock, setCreateBlock] = useState(false);
    const [fixRowSpacing, setFixRowSpacing] = useState(false);
    const [rollbackThreshold, setRollbackThreshold] = useState("");
    const [forceTrunkIngestion, setForceTrunkIngestion] = useState(false);
    const [disableTrunkNudge, setDisableTrunkNudge] = useState(false);
    const [disableAggressiveRowMerge, setDisableAggressiveRowMerge] = useState(false);

    const handleConfirmRelay = (e) => {
        e.preventDefault();
        handleConfirm(
            orchardName,
            scanNumber,
            numSubrows,
            forceColinear,
            createBlock,
            fixRowSpacing,
            rollbackThreshold,
            forceTrunkIngestion,
            disableTrunkNudge,
            disableAggressiveRowMerge,
        );
    };

    const handleCancelRelay = (e) => {
        e.preventDefault();
        handleCancel();
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">Specify orchard code and scan number to delete or upload scan.</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={orchardName}
                        onChange={e => setOrchardName(e.target.value)}
                        label="Orchard (eg. CHI)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={scanNumber}
                        onChange={e => setScanNumber(e.target.value)}
                        label="Scans, comma separated (eg. avatar_73,552)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={numSubrows}
                        onChange={e => setNumSubrows(e.target.value)}
                        label="Normally Blank. Number of subrows per row (eg. 1, 2 (for v-trellis))"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={rollbackThreshold}
                        onChange={e => setRollbackThreshold(e.target.value)}
                        label="Normally Blank. Set minimum tree placement threshold (eg. 0.70)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                </div>
                <div className="flex space-x-2 mb-4">
                    <FormControlLabel
                        control={<Checkbox
                            checked={forceColinear}
                            onChange={e => setForceColinear(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Force trees to be colinear" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={createBlock}
                            onChange={e => setCreateBlock(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Create block if none is found" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={fixRowSpacing}
                            onChange={e => setFixRowSpacing(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Fix row spacing" />
                </div>
                <div className="flex space-x-2 mb-4">
                    <FormControlLabel
                        control={<Checkbox
                            checked={forceTrunkIngestion}
                            onChange={e => setForceTrunkIngestion(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Force trees to be ingested for everything scans" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={disableTrunkNudge}
                            onChange={e => setDisableTrunkNudge(e.target.checked)} // trunk_nudge_enabled
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Disable Nudging Trunks" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={disableAggressiveRowMerge}
                            onChange={e => setDisableAggressiveRowMerge(e.target.checked)} // aggressive_row_merge_enabled
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Disable Aggressive Row Merging" />
                </div>

                {/* <Button type="submit" variant="outline" color="primary">
                    Submit
                </Button> */}
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleConfirmRelay}
                    >Confirm</button>
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleCancelRelay}
                    >Cancel</button>
                </div>
            </form>
        </div>
    );
};

export const OffsetScan = () => {
    const [orchardName, setOrchardName] = useState("");
    const [scanName, setScanName] = useState("");
    const [offsetLon, setOffsetLon] = useState("");
    const [offsetLat, setOffsetLat] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    // const history = useHistory();

    const handleOffset = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Offset running!");
        axios.post('/admin/offset_scan_points', {
            orchard_name: orchardName,
            scan_name: scanName,
            offset: {
                lon: offsetLon,
                lat: offsetLat
            }
        }).then(res => {
            console.log(res.data);
            // setUploadDialogOpen(false);
            setDialogMessage("Offset run successfully!");
        }).catch(error => {
            setDialogMessage("Upload failed! :(");
            console.error(error);
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadDialogOpen(false);
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">Specify orchard code and scan number to offset a master trunk scan.</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={orchardName}
                        onChange={e => setOrchardName(e.target.value)}
                        label="Orchard (eg. CHI)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={scanName}
                        onChange={e => setScanName(e.target.value)}
                        label="Scan (eg. 551)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={offsetLat}
                        onChange={e => setOffsetLat(e.target.value)}
                        label="Relative latitudes to offset scan"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={offsetLon}
                        onChange={e => setOffsetLon(e.target.value)}
                        label="Relative longitudes to offset scan"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                </div>

                {/* <Button type="submit" variant="outline" color="primary">
                    Submit
                </Button> */}
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleOffset}
                    >Offset Scan</button>
                </div>
            </form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={uploadDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
        </div>
    );
};

export const ScanForm = ({selectedScans}) => {
    const [orchardName, setOrchardName] = useState("");
    const [scanNumber, setScanNumber] = useState("");
    const [numSubrows, setNumSubrows] = useState("");
    const [forceColinear, setForceColinear] = useState(false);
    const [createBlock, setCreateBlock] = useState(false);
    const [fixRowSpacing, setFixRowSpacing] = useState(false);
    const [rollbackThreshold, setRollbackThreshold] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [forceTrunkIngestion, setForceTrunkIngestion] = useState(false);
    const [disableTrunkNudge, setDisableTrunkNudge] = useState(false);
    const [disableAggressiveRowMerge, setDisableAggressiveRowMerge] = useState(false);

    const handleDelete = (e) => {
        e.preventDefault();
        setDialogOpen(true);
        setDialogMessage("Deletion running!");
        axios.post('/admin/scan_deletion', {
            orchard_name: orchardName,
            scan_number: scanNumber
        }).then(res => {
            console.log(res.data);
            setDialogMessage("Deletion run successfully!");
        }).catch(error => {
            setDialogMessage("Delete failed! :(");
            console.error(error);
        });
    };

    const handleUpload = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("upload running!");
        axios.post('/admin/scan_upload', {
            orchard_name: orchardName,
            scan_number: scanNumber,
            num_subrows: numSubrows,
            force_colinear: forceColinear,
            create_block: createBlock,
            fix_row_spacing: fixRowSpacing,
            rollback_threshold: rollbackThreshold,
            force_trunk_ingestion: forceTrunkIngestion,
            trunk_nudge_enabled: !disableTrunkNudge,
            aggressive_row_merge_enabled: !disableAggressiveRowMerge,
        }).then(res => {
            console.log(res.data);
            setDialogMessage("Upload queued successfully!");
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                setDialogMessage("Error: scan(s) not found in bucket");
            } else {
                setDialogMessage("Upload failed! :(");
            }
            console.error(error);
        });
    };

    const handlePregenerate = (e) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        setDialogMessage("Pregeneration running!");
        axios.post('/scans/pregenerate', {
            orchard_name: orchardName,
            scan_number: scanNumber,
            num_subrows: numSubrows,
            force: true,
        }).then(res => {
            console.log(res.data);
            setDialogMessage("Pregeneration run successfully!");
        }).catch(error => {
            setDialogMessage("Pregeneration failed! :(");
            console.error(error);
        });
    };

    const getSelectedScans = (e) => {
        e.preventDefault();
        let scanNumbers = [];
        for (let scanName of selectedScans) {
            const scanNum = scanName.split('_')[1];
            scanNumbers.push(scanNum);
        }
        setScanNumber(scanNumbers.join(','))
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadDialogOpen(false);
    };

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">Specify orchard code and scan number to delete or upload scan.</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={orchardName}
                        onChange={e => setOrchardName(e.target.value)}
                        label="Orchard (eg. CHI)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={scanNumber}
                        onChange={e => setScanNumber(e.target.value)}
                        label="Scans, comma separated (eg. 551,552,553)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={numSubrows}
                        onChange={e => setNumSubrows(e.target.value)}
                        label="Normally Blank. Number of subrows per row (eg. 1, 2 (for v-trellis))"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={rollbackThreshold}
                        onChange={e => setRollbackThreshold(e.target.value)}
                        label="Normally Blank. Set minimum tree placement threshold (eg. 0.70)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                </div>
                <div className="flex space-x-2 mb-4">
                    <FormControlLabel
                        control={<Checkbox
                            checked={forceColinear}
                            onChange={e => setForceColinear(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Force trees to be colinear" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={createBlock}
                            onChange={e => setCreateBlock(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Create block if none is found" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={fixRowSpacing}
                            onChange={e => setFixRowSpacing(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Fix row spacing" />
                </div>
                <div className="flex space-x-2 mb-4">
                    <FormControlLabel
                        control={<Checkbox
                            checked={forceTrunkIngestion}
                            onChange={e => setForceTrunkIngestion(e.target.checked)}
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Force trees to be ingested for everything scans" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={disableTrunkNudge}
                            onChange={e => setDisableTrunkNudge(e.target.checked)} // trunk_nudge_enabled
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Disable Nudging Trunks" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={disableAggressiveRowMerge}
                            onChange={e => setDisableAggressiveRowMerge(e.target.checked)} // aggressive_row_merge_enabled
                            variant="outlined"
                            labelPlacement="end"
                        />
                        }
                        label="Disable Aggressive Row Merging" />
                </div>

                {/* <Button type="submit" variant="outline" color="primary">
                    Submit
                </Button> */}
                <div className="flex space-x-2 w-full">
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleUpload}
                    >Upload</button>
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handleDelete}
                    >Delete</button>
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={handlePregenerate}
                    >Pregenerate</button>
                    <button style={{ maxWidth: '350px' }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={getSelectedScans}
                    >Get Selection</button>
                </div>
            </form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={uploadDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
        </div>
    );
};

export const ScanPortal = ({selectedScans, setSelectedScans}) => {
    const [orchardName, setOrchardName] = useState("");
    const [cameraName, setCameraName] = useState("");
    const [scanInfo, setScanInfo] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [uploadOptionsOpen, setUploadOptionsOpen] = useState(false);
    const [uploadOptionsScanNumber, setUploadOptionsScanNumber] = useState('');
    const [deleteReasonOpen, setDeleteReasonOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [selectedScanRawName, setSelectedScanRawName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteConfirm = () => {
        setDeleteReasonOpen(false);
        setDialogOpen(true);
        setDialogMessage("Deletion running!");
        const orchardCode = selectedScanRawName.split("_")[0];
        const scanNumberStr = selectedScanRawName.split("_")[1];
        axios.post('/admin/scan_deletion', {
            orchard_name: orchardCode,
            scan_number: scanNumberStr,
            delete_reason: deleteReason
        })
        .catch(error => {
            setDialogMessage("Delete failed! :(");
            console.error(error);
        })
        .finally(() => {
            getScanList();
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        getScanList();
    };

    const handleClearFilters = () => {
        setOrchardName("");
        setCameraName("");
    };

    const getScanList = () => {
        setIsLoading(true);
        axios.get('/scans/list_from_bucket', {
            params: {
                orchard_code: orchardName,
                camera_name: cameraName || undefined,
            }
        }).then(res => {
            setScanInfo(res.data);
            setIsLoading(false);
        }).catch(error => {
            setDialogMessage("Couldn't get scan list! :(");
            console.error(error);
            setIsLoading(false);
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUploadDialogOpen(false);
    };

    const handleDeleteReasonClose = () => {
        setDeleteReasonOpen(false);
        setDeleteReason("");
    };

    const handleUploadOptionsClose = () => {
        setUploadOptionsOpen(false);
        // setDeleteReason("");
    };

    const handleBulkDelete = (e, selectedIndices) => {
        e.preventDefault();
        setDialogOpen(true);
        const rowData = getRowsFromScanInfo();
        let scanNumber = selectedIndices.map((selectedIndex) => {
            return parseScanStr(rowData[selectedIndex].name).scanName;
        })
        scanNumber = scanNumber.join(",")
        setDialogMessage("Deletion running!");
        axios.post('/admin/scan_deletion', {
            orchard_name: orchardName,
            scan_number: scanNumber
        }).then(res => {
            console.log(res.data);
            // setDialogOpen(false);
            setDialogMessage("Deletion run successfully!");
        }).catch(error => {
            setDialogMessage("Delete failed! :(");
            console.error(error);
        });
    };

    const handleUploadOptionsOpen = (e, selectedIndices) => {
        e.preventDefault();
        const rowData = getRowsFromScanInfo();
        let scanNumber = selectedIndices.map((selectedIndex) => {
            return parseScanStr(rowData[selectedIndex].name).scanName;
        })
        scanNumber = scanNumber.join(",")
        setUploadOptionsScanNumber(scanNumber)
        setUploadOptionsOpen(true);
    };

    const handleUploadOptionsConfirm = (
        orchardName,
        scanNumber,
        numSubrows,
        forceColinear,
        createBlock,
        fixRowSpacing,
        rollbackThreshold,
        forceTrunkIngestion,
        disableTrunkNudge,
        disableAggressiveRowMerge,
    ) => {
        setUploadDialogOpen(true);
        setDialogMessage("upload running!");
        axios.post('/admin/scan_upload', {
            orchard_name: orchardName,
            scan_number: scanNumber,
            num_subrows: numSubrows,
            force_colinear: forceColinear,
            create_block: createBlock,
            fix_row_spacing: fixRowSpacing,
            rollback_threshold: rollbackThreshold,
            force_trunk_ingestion: forceTrunkIngestion,
            trunk_nudge_enabled: !disableTrunkNudge,
            aggressive_row_merge_enabled: !disableAggressiveRowMerge,
        }).then(res => {
            console.log(res.data);
            setDialogMessage("Upload queued successfully!");
        }).catch(error => {
            setDialogMessage("Upload failed! :(");
            console.error(error);
        });
    };

    const handleBulkPregenerate = (e, selectedIndices) => {
        e.preventDefault();
        setUploadDialogOpen(true);
        const rowData = getRowsFromScanInfo();
        let scanNumber = selectedIndices.map((selectedIndex) => {
            return parseScanStr(rowData[selectedIndex].name).scanName;
        })
        scanNumber = scanNumber.join(",")
        setDialogMessage("Pregeneration running!");
        axios.post('/scans/pregenerate', {
            orchard_name: orchardName,
            scan_number: scanNumber,
            // num_subrows: numSubrows,
            force: true,
        }).then(res => {
            console.log(res.data);
            // setUploadDialogOpen(false);
            setDialogMessage("Pregeneration run successfully!");
        }).catch(error => {
            setDialogMessage("Pregeneration failed! :(");
            console.error(error);
        });
    };

    const selectActions = [
        {
            tooltip: "Delete",
            action: handleBulkDelete,
            render: <DeleteIcon />
        },
        {
            tooltip: "Upload",
            action: handleUploadOptionsOpen,
            render: <UploadIcon />
        },
        {
            tooltip: "Pregenerate",
            action: handleBulkPregenerate,
            render: <SyncIcon />
        },
    ];

    const getRowsFromScanInfo = () => {
        return Object.entries(scanInfo).map((item, index) => {
            const scanInfo = parseScanStr(item[0]);
            const cameraName = scanInfo?.cameraName || "N/A";
            
            return {
                id: index,
                name: item[0],
                ingested: item[1],
                actions: (
                    <div className="flex space-x-2">
                        <IconButton 
                            onClick={(e) => handleBulkDelete(e, [index])}
                            size="small"
                            title="Delete"
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton 
                            onClick={(e) => handleUploadOptionsOpen(e, [index])}
                            size="small"
                            title="Upload"
                        >
                            <UploadIcon />
                        </IconButton>
                        <IconButton 
                            onClick={(e) => handleBulkPregenerate(e, [index])}
                            size="small"
                            title="Pregenerate"
                        >
                            <SyncIcon />
                        </IconButton>
                    </div>
                )
            };
        });
    };

    const columnFuncs = [
        (row) => {
            return <center>{row.ingested ? "✅" : "❌"}</center>;
        },
        (row) => {
            return <a className="underline" target="_blank" rel="noopener noreferrer" href={`https://console.cloud.google.com/storage/browser/_details/prod_scan_upload_zips/${row.name}.zip`}>Prod Zip Link</a>;
        },
        (row) => row.actions,
    ];

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'ingested',
            numeric: false,
            disablePadding: true,
            label: 'Ingested',
        },
        {
            id: 'link',
            numeric: false,
            disablePadding: true,
            label: 'Link',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: true,
            label: 'Actions',
        },
    ];

    return (
        <div className="flex flex-col items-center justify-center font-lato">
            <p className="text-lg text-center mb-4">View the status of each scan in an Orchard</p>
            <form className="flex flex-col items-center">
                <div className="flex space-x-2 mb-4">
                    <CustomTextField
                        value={orchardName}
                        onChange={e => setOrchardName(e.target.value)}
                        label="Orchard (eg. CHI)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                    <CustomTextField
                        value={cameraName}
                        onChange={e => setCameraName(e.target.value)}
                        label="Camera Name (optional)"
                        variant="outlined"
                        style={{ width: '300px' }}
                    />
                </div>

                <div className="flex space-x-2 w-full justify-center mb-4">
                    <button 
                        style={{ maxWidth: '350px' }}
                        className={`${isLoading ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded w-full mt-2 flex justify-center items-center`}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <span className="mr-2">Loading...</span>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </>
                        ) : (
                            'Get Scan Status'
                        )}
                    </button>
                    <button 
                        style={{ maxWidth: '150px' }}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded w-full mt-2" 
                        onClick={(e) => {
                            e.preventDefault();
                            handleClearFilters();
                        }}
                        disabled={isLoading}
                    >
                        Clear
                    </button>
                </div>
                <EnhancedTable rows={getRowsFromScanInfo()} columnFuncs={columnFuncs} headCells={headCells} title="Scans" selectActions={selectActions} />
            </form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={uploadDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{dialogMessage}</DialogTitle>
            </Dialog>
            <Dialog
                open={deleteReasonOpen}
                onClose={handleDeleteReasonClose}
            >
                <DialogTitle>Enter Delete Reason (Optional)</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Delete Reason"
                        fullWidth
                        value={deleteReason}
                        onChange={e => setDeleteReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteReasonClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={uploadOptionsOpen}
                onClose={handleUploadOptionsClose}
                fullWidth={true}
                maxWidth={false}
            >
                <DialogTitle>Enter Delete Reason (Optional)</DialogTitle>
                <DialogContent>
                    <UploadOptions orchardName={orchardName} setOrchardName={setOrchardName} scanNumber={uploadOptionsScanNumber} setScanNumber={setUploadOptionsScanNumber} handleConfirm={handleUploadOptionsConfirm} handleCancel={handleUploadOptionsClose} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export const Admin = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handlePasswordCheck = password => {
        if (password === 'fruity') {
            setIsAuthenticated(true);
        }
    };

    return (
        <div>
            {!isAuthenticated ? (
                <PasswordForm onPasswordCheck={handlePasswordCheck} />
            ) : (
                <AdminOptions />
            )}
        </div>
    );
};

export const SQLQueryInterface = () => {
    const [prompt, setPrompt] = useState("");
    const [orchardCode, setOrchardCode] = useState("");
    const [queryAllOrchards, setQueryAllOrchards] = useState(false);
    const [results, setResults] = useState(null);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const trackEvent = useTrackEvent();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        
        try {
            const response = await axios.post('/admin/ask_sql', { 
                prompt,
                orchardCode: queryAllOrchards ? null : orchardCode.toUpperCase()
            });
            setResults(response.data.results);
            setQuery(response.data.query);
            trackEvent('admin_sql_query', {
                prompt,
                orchardCode: orchardCode.toUpperCase(),
                query: response.data.query
            });
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred');
            setQuery(err.response?.data?.query || '');
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(query);
    };

    const handleOrchardCodeChange = (e) => {
        const value = e.target.value.toUpperCase();
        if (value.length <= 3 && /^[A-Z]*$/.test(value)) {
            setOrchardCode(value);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!loading && (queryAllOrchards || orchardCode.length === 3)) {
                handleSubmit(e);
            }
        }
    };

    return (
        <div className="w-full max-w-6xl mx-auto p-4 space-y-6">
            <div className="flex flex-col space-y-4">
                <div className="flex space-x-4 items-center">
                    <TextField
                        disabled={queryAllOrchards}
                        value={orchardCode}
                        onChange={handleOrchardCodeChange}
                        label="Orchard Code (3 letters)"
                        variant="outlined"
                        className="bg-white w-48"
                        error={orchardCode.length > 0 && orchardCode.length < 3}
                        helperText={orchardCode.length > 0 && orchardCode.length < 3 ? "Must be 3 letters" : ""}
                    />
                    {/* TODO: Implement this*/}
                    {/* <FormControlLabel
                        control={
                            <Checkbox
                                checked={queryAllOrchards}
                                onChange={(e) => {
                                    setQueryAllOrchards(e.target.checked);
                                    if (e.target.checked) {
                                        setOrchardCode("");
                                    }
                                }}
                            />
                        }
                        label="Query All Orchards"
                    /> */}
                </div>
                
                <TextField
                    style={{ width: '50%' }}
                    multiline
                    rows={3}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    // onKeyDown={handleKeyPress}
                    label="Ask a question about the database"
                    variant="outlined"
                    className="bg-white"
                />
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading || (!queryAllOrchards && orchardCode.length !== 3)}
                    className="bg-blue-500 hover:bg-blue-700 w-1/2"
                >
                    {loading ? 'Querying...' : 'Submit Query'}
                </Button>
            </div>

            {error && (
                <Alert severity="error" className="mt-4">
                    {error}
                </Alert>
            )}

            {(results || query) && (
                <div className="grid grid-cols-2 gap-4">
                    <div className="border rounded-lg p-4 bg-white">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-semibold">
                                Results {results && `(${results.length} rows)`}
                            </h3>
                        </div>
                        <div className="overflow-auto max-h-96">
                            {results && (
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                {results.length > 0 && 
                                                    Object.keys(results[0]).map((header) => (
                                                        <TableCell key={header}>{header}</TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.map((row, i) => (
                                                <TableRow key={i}>
                                                    {Object.values(row).map((value, j) => (
                                                        <TableCell key={j}>{value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </div>
                    </div>

                    <div className="border rounded-lg p-4 bg-white">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-semibold">Generated SQL Query</h3>
                            <IconButton onClick={copyToClipboard} size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </div>
                        <pre className="bg-gray-100 p-4 rounded overflow-auto max-h-96">
                            {query}
                        </pre>
                    </div>
                </div>
            )}
        </div>
    );
};
