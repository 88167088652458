import { Collapse } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { EntityType } from '../common/types';
import { entityTypeConfig } from '../constants/entityTypeConfig';
import { useMapState } from '../context/MapStateContext';
import { Card } from './Cards';
import { MetricItem } from './Sidebar';
import CalibratedStatsToggle from './toggles/CalibratedStatsToggle.js';
import EstimateBlockToggle from "./toggles/EstimateBlockToggle.js";
import UnitToggle from './toggles/UnitToggle.js';
import { getTerminology } from '../i18n';

function capitalizeFirstLetter(string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const ScanViewStats = ({ entityType, stats, stageType }) => {
    const { isImperial, setIsImperial } = useMapState();
    const [estimateBlock, setEstimateBlock] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const hasCalibrations = stats.total_fruit_calibrated > 0;
    const [viewCalibratedStats, setViewCalibratedStats] = useState(hasCalibrations)
    const entityConfig = entityTypeConfig(isImperial, stageType, estimateBlock, viewCalibratedStats).metrics || []; 

    const isMalformed = (value) => {
        return value === null || value === undefined || Number.isNaN(value) || value === '' || value === 0 || value === -1;
    };

    return (
        <>
            <Card title={`${capitalizeFirstLetter(entityType)} Scan Statistics`} showButton defaultOpen={true} description={'View statistics about the selected scan.'}>
                <p className="text-xs text-gray-500 cursor-pointer hover:text-gray-700 text-right" onClick={() => setExpandAll(!expandAll)}>
                    {expandAll ? 'Collapse All' : 'Expand All'}
                </p>
                {entityConfig.map((metricConfig, index) => {
                    const metricValue = stats[metricConfig.metricPath];
                    if (isMalformed(metricValue)) {
                        return null;
                    }

                    return (
                        <MetricComponent
                            key={index}
                            config={metricConfig}
                            data={stats}
                            expandAll={expandAll}
                        />
                    );
                })}

                <UnitToggle isImperial={isImperial} setIsImperial={setIsImperial} />

                {entityType === EntityType.Fruits && <EstimateBlockToggle estimateBlock={estimateBlock} setEstimateBlock={setEstimateBlock} />}

                {hasCalibrations && <CalibratedStatsToggle viewCalibratedStats={viewCalibratedStats} setViewCalibratedStats={setViewCalibratedStats} />}
            </Card>
        </>
    );
}


export const MetricComponent = ({ config, data, expandAll }) => {
    const isRendered = config.condition(data);
    let value;
    if (config.metricPath) {
        value = data[config.metricPath];
        if (config.valueTransform && value) {
            value = config.valueTransform(value, data);
        }
    }

    if (!isRendered) {
        return null;
    }

    const hasChildren = config.children && config.children.length > 0;

    return (
        <div className="relative">
            <Tooltip title={getTerminology(config.description)} placement="right">
                <div 
                    className={`
                        ${config.color ? config.color(data) : ''} 
                    `}
                    onClick={() => hasChildren && expandAll}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex-grow">
                            <MetricItem
                                key={config.key}
                                icon={config.icon}
                                metric={value}
                                label={getTerminology(config.label)}
                                units={getTerminology(config.units)}
                                infoTip={getTerminology(config.infoTip)}
                                className="flex justify-between"
                            />
                        </div>
                    </div>
                </div>
            </Tooltip>

            {hasChildren && (
                <Collapse in={expandAll}>
                    <div className="ml-4 mt-1 border-l-2 border-gray-200 pl-4">
                        {config.children.map((childConfig, index) => (
                            <MetricComponent
                                key={childConfig.key}
                                config={childConfig}
                                data={data}
                            />
                        ))}
                    </div>
                </Collapse>
            )}

            {config.sublabel && (
                <div className="mt-1 text-xs text-red-500">{config.sublabel(data)}</div>
            )}
        </div>
    );
};
